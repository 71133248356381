<template>
  <div class="composite-material-transit">
    <CommonParagraph title="Matériel à envoyer pour le mois à venir :">
      <CompositeTodo
        :items="material_to_send_options"
        v-model="material_to_send"
        @onClear="remove"
      />
      <CommonButton v-if="!showSelect && security.isAdmin" icon="Plus" @click="showSelect = true"
        >Ajouter un pack inventaire supplémentaire</CommonButton
      >
      <LabelInfo v-if="material_to_send_options.length === 0 && security.isAdminReadonly">
        Aucun matériel à envoyer.
      </LabelInfo>
      <Transition>
        <div v-if="showSelect">
          <div class="cancel" @click="showSelect = false">
            <ArrowLeft :size="16" />
            <p>Annuler</p>
          </div>
          <InputSelect
            id="material-select"
            label="Ajouter un pack manuellement"
            placeholder="Sélectionnez un pack"
            :options="
              appdata.getPackOptions.filter((e) => {
                return !material_to_send_options.find((o) => o.value === e.value)
              })
            "
            @update:model-value="add"
          />
        </div>
      </Transition>
    </CommonParagraph>
    <CommonParagraph title="Matériel déclaré manquant par l'intervenant :">
      <CompositeTodo
        class="mb-30"
        :items="
          store.missing_materials.map((e) => ({
            label: e.content ?? '',
            value: e.id?.toString() ?? ''
          }))
        "
        v-model="material_missing"
        @onClear="remove_missing"
      />
      <template v-if="security.isAdmin">
        <InputCommon
          id="consultant-search"
          label="Ajouter un matériel manquant"
          v-model="missing_input"
          placeholder="Description du matériel manquant"
        />
        <CompositeTodo class="mb-20" :items="[]" v-model="material_missing" />
        <CommonButton icon="Plus" @click="add_missing" :disabled="isEmpty()">
          Ajouter un matériel manquant supplémentaire
        </CommonButton>
      </template>
      <LabelInfo v-if="store.missing_materials.length === 0 && security.isAdminReadonly">
        Aucun matériel à envoyer.
      </LabelInfo>
    </CommonParagraph>
    <CommonParagraph title="Pochettes à envoyer">
      <LayoutTwoColumn v-if="getKits().length > 0">
        <CompositePouchCard v-model="pouches_to_send" :items="getKits()" />
      </LayoutTwoColumn>
      <LabelInfo v-else> Aucune pochette à envoyer. </LabelInfo>
    </CommonParagraph>
    <CommonButton
      style="width: 100%"
      filled
      color="success"
      icon="Check"
      :disabled="canValidate()"
      @click="sendPacks()"
      :loading="store.isFetching"
    >
      Valider l'envoi + générer un bon de livraison
    </CommonButton>
    <CompositeItemList title="Bons de livraisons" hasLinks :items="store.getPackHistoryItems" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useMaterialStore } from '@/stores/gae/store_material'
import { useSecurityStore } from '@/stores/gae/store_security'
import InputSelect from '../common/input/InputSelect.vue'
import CommonButton from '../common/CommonButton.vue'
import CommonParagraph from '../common/CommonParagraph.vue'
import CompositeItemList from './CompositeItemList.vue'
import CompositeTodo from './CompositeTodo.vue'
import type { Consultant } from 'types/api/consultant'
import CompositePouchCard from './CompositePouchCard.vue'
import LayoutTwoColumn from '../layout/LayoutTwoColumn.vue'
import type { PouchOption } from './CompositePouchCard.vue'
import { useAppData } from '@/stores/gae/store_appdata'
import InputCommon from '../common/input/InputCommon.vue'
import { ArrowLeft } from 'lucide-vue-next'
import LabelInfo from './label/LabelInfo.vue'

const props = defineProps<{
  consultant: Consultant
}>()

const store = useMaterialStore()
const appdata = useAppData()
const security = useSecurityStore()

const material_to_send_options = ref<SelectOption[]>([])
const material_to_send = ref<string[]>([])
const material_missing = ref<string[]>([])
const pouches_to_send = ref<string[]>([])
const showSelect = ref(false)
const missing_input = ref('')

const sendPacks = () => {
  const packsToSend = {
    packs: material_to_send.value.map((e) => parseInt(e)),
    missing_materials: material_missing.value.map((e) => parseInt(e)),
    interventions: pouches_to_send.value.map((e) => parseInt(e))
  }

  store.sendPacks(props.consultant.id, packsToSend)
}

function getKits(): PouchOption[] {
  const kits = store.kits.map((e) => ({
    value: e.id.toString(),
    date: e.date,
    packName: e.title,
    skill: e.skills[0].category,
    customer: e.customer
  }))

  return kits.map((kit) => ({
    value: kit.value,
    date: kit.date,
    packName: kit.packName,
    skill: kit.skill,
    customer: kit.customer ?? ''
  }))
}

function isEmpty() {
  return missing_input.value === ''
}

function add(selected_value: string) {
  const pack = appdata.getPackOptions.find((e) => e.value === selected_value)
  if (pack) {
    material_to_send_options.value.push({
      label: pack.label,
      value: pack.value
    })
    material_to_send.value.push(pack.value)
  }
}

async function add_missing() {
  const missing = await store.addMissingMaterial(props.consultant.id, missing_input.value)
  if (missing && missing.id) {
    material_missing.value.push(missing.id.toString())
    missing_input.value = ''
  }
}

function remove_missing(value: string) {
  if (confirm('Supprimer le matériel manquant ?')) {
    store.removeMissingMaterial(parseInt(value))
  }
}

function remove(option: string) {
  const index = material_to_send_options.value.findIndex((e) => e.value === option)
  const pack = material_to_send_options.value.find((e) => e.value === option)
  if (pack && index !== -1) {
    material_to_send_options.value.splice(index, 1)
    appdata.getPackOptions.splice(index, 0, pack)
  }
}

function canValidate() {
  return (
    material_to_send.value.length === 0 &&
    material_missing.value.length === 0 &&
    pouches_to_send.value.length === 0
  )
}

onMounted(() => {
  store.fetchSend(props.consultant.id).then(() => {
    material_to_send_options.value = store.to_send.map((e) => ({
      label: e.name,
      value: e.id.toString()
    }))
  })
  store.fetchPackHistory(props.consultant.id)
  store.fetchMissingMaterials(props.consultant.id)
})
</script>

<style scoped>
.composite-material-transit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cancel {
  cursor: pointer;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: fit-content;
  color: var(--vc-dark);
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}
.cancel:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  gap: 16px;
}
</style>
