<template>
  <div class="composite-material">
    <CommonLoader v-if="material_store.isFetching" />
    <CommonParagraph title="Matériel en possession de l'intervenant :">
      <div v-if="material_store.inventory">
        <div class="pack-wrapper" v-if="material_store.inventory.packs.length > 0">
          <div
            v-for="pack of material_store.inventory.packs"
            :key="pack.id"
            @mouseenter="hoverPack = pack.id"
            @mouseleave="hoverPack = null"
          >
            <CommonChip @click.stop="remove(pack)" size="large" color="success" filled>
              {{ pack.name }}
              <X :size="15" style="margin-left: 5px" v-if="security.isAdmin" />
            </CommonChip>
            <Transition>
              <div
                v-if="
                  hoverPack === pack.id &&
                  isFirstPackWithId(pack) &&
                  pack.content !== 'missing-material'
                "
                v-html="pack.content"
                class="hover-details"
              ></div>
            </Transition>
          </div>
        </div>
        <LabelInfo v-else> Cet intervenant ne dispose d'aucun matériel. </LabelInfo>
      </div>
    </CommonParagraph>
    <InputSelect
      v-if="security.isAdmin"
      id="material-select"
      label="Ajouter un pack manuellement"
      placeholder="Sélectionnez un pack"
      :options="appdata.getPackOptions"
      @update:model-value="add"
    />
    <CommonParagraph title="Historique">
      <div v-if="material_store.inventoryHistory">
        <div v-if="material_store.inventoryHistory.length > 0">
          <CompositeItemList title="Inventaire" :items="material_store.getInventoryHistoryItems" />
        </div>
        <LabelInfo v-else> Cet intervenant n'a pas d'historique. </LabelInfo>
      </div>
    </CommonParagraph>
  </div>
</template>

<script setup lang="ts">
import { useMaterialStore } from '@/stores/gae/store_material'
import { useSecurityStore } from '@/stores/gae/store_security'
import CommonLoader from '../common/CommonLoader.vue'
import { onMounted, ref } from 'vue'
import CommonChip from '@/components/common/CommonChip.vue'
import type { Consultant } from 'types/api/consultant'
import { X } from 'lucide-vue-next'
import CommonParagraph from '../common/CommonParagraph.vue'
import CompositeItemList from './CompositeItemList.vue'
import InputSelect from '../common/input/InputSelect.vue'
import LabelInfo from '@/components/composite/label/LabelInfo.vue'
import { useAppData } from '@/stores/gae/store_appdata'
import type { APIPackGETResponse, Pack } from 'types/api/material'

const material_store = useMaterialStore()
const security = useSecurityStore()
const appdata = useAppData()
const hoverPack = ref<number | null>(null)

const props = defineProps<{
  consultant: Consultant
}>()

function remove(pack: APIPackGETResponse) {
  if (!confirm('Supprimer le pack ?')) return
  material_store.removePackFromInventory(props.consultant.id, pack.id)
  if (pack.content === 'missing-material') {
    material_store.deletePack(pack.id)
  }
}
function add(select_value: string) {
  const id = parseInt(select_value)
  material_store.addPackToInventory(props.consultant.id, id)
}

// When sames packs in inventory, hover information only for one pack
function isFirstPackWithId(pack: Pack) {
  if (!material_store.inventory) return
  const firstPackWithId = material_store.inventory.packs.find((p) => p.id === pack.id)
  return firstPackWithId === pack
}

onMounted(() => {
  material_store.fetchInventory(props.consultant.id)
  material_store.fetchInventoryHistory(props.consultant.id)
})
</script>

<style scoped>
.composite-material {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pack-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  transition: 0.3s;
}

.pack-wrapper > div > div:first-of-type {
  margin-bottom: 10px;
  cursor: pointer;
}

.pack-wrapper > div > div:last-of-type {
  cursor: default;
}

.hover-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 1rem;
  background-color: white;
  position: absolute;
  z-index: 99;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  transition: 0.3s;
  max-height: 300px;
  overflow-y: auto;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
