import { useCookies } from '@/composable/cookies';
import { defineStore } from 'pinia'
import { useGAEAPI } from '@/composable/gae_api'
import { handleErrorNotification } from '@/services/service_api'
import type { DynformEntryPOST, DynformQuestion, DynformQuestionGET, DynformEntryInterventionPOST, DynformAnswerDTO, DynformEntry, Dynform, DynformGET } from 'types/api/dynform';
import { useToast } from 'vue-toast-notification';
import { useRequestStore } from './store_requests';
import { useInterventionStore } from './store_intervention';

const toast = useToast()

type DynformStoreState = {
    form_dynform_questions: Map<number, orderedQuestion[]>
    dynform_entries: Map<number, DynformEntry[]> // key: step_id
    dynform_questions: DynformQuestion[],
    in_group: boolean
    selected_category?: string
    isFetching?: boolean
    intervention_id?: number
    dynform?: Dynform
}

export type DynformStepData = {
    step: number
    label: string
    completion: number
}

export const STEPS: { [key: number]: string } = {
    0: 'Informations participant',
    1: 'Feuille de présence',
    2: 'Questionnaire de satisfaction',
    3: 'Évaluation acquis',
    4: 'Emargement',
    5: 'Évaluation Intervenant',
    7: 'Attestation'
}

export type orderedQuestion = DynformQuestion & { answer: any, answered: boolean }

export const useDynformStore = defineStore('dynform', {
    state: (): DynformStoreState => ({
        isFetching: false,
        form_dynform_questions: new Map(),
        dynform_entries: new Map(),
        in_group: false,
        selected_category: '',
        intervention_id: undefined,
        dynform_questions: [],
        dynform: undefined,
    }),
    getters: {
        getDynformQuestions: (state) => state.form_dynform_questions,
        getAnswersByIntervention: (state) => state.dynform_entries,
        getAnswerQuestion: (state) => {
            return (answer: DynformAnswerDTO): DynformQuestion | undefined => {
                const question = state.dynform_questions.find(e => e.id === answer.question_id);
                return question
            }
        },
        getAnswersWithFlag: (state) => {
            return (entry: DynformEntry, flag: string): DynformAnswerDTO[] => {
                const output: DynformAnswerDTO[] = []
                for (const answer of entry.answers) {
                    const question = state.dynform_questions.find(e => e.id === answer.question_id);
                    if (!question || question.flags === undefined) continue
                    if (question.flags.includes(flag)) {
                        output.push(answer)
                    }
                }

                return output
            }
        },
        getConsultantTraineeFormQuestions: (state) => {
            const questions = state.form_dynform_questions.get(7);
            if (!questions || questions.length === 0) return [];
            return questions.filter(e => e.flags.includes('FLAG_INDIVIDUAL'));
        },
        getEntryStepsData: (state) => {
            return (entry: DynformEntry): DynformStepData[] => {
                const output: DynformStepData[] = [];
                const intervention_store = useInterventionStore()
                const intervention = intervention_store.getInterventionByID(entry.intervention_id)
                if (!state.form_dynform_questions) return []
                const dynform = new Map(state.form_dynform_questions);
                for (const [step, questions] of dynform) {
                    let answered = 0;

                    const filtered_questions = questions.filter(e => {

                        if (e.flags.includes('FLAG_GROUPS_MULTIPLE')) {
                            return true;
                        } else if (e.flags.includes('FLAG_FULLDAY')) {

                        }

                        if (
                            e.flags.includes('FLAG_REQUIRED') && !e.flags.includes('FLAG_CONSULTANT')
                        ) {
                            return true;
                        }
                    });
                    if (filtered_questions.length === 0) continue;


                    for (const question of filtered_questions) {
                        const answer = entry.answers.find(e => e.question_id === question.id);
                        if (answer) {
                            answered++;
                        }
                    }

                    output.push({
                        label: STEPS[step],
                        step: step,
                        completion: Math.round((answered * 100) / filtered_questions.length)
                    })
                }

                return output;
            }
        },
        getConsultantEntryStepsData: (state) => {
            return (entry: DynformEntry): DynformStepData[] => {
                const output: DynformStepData[] = [];
                if (!state.form_dynform_questions) return []
                const dynform = new Map(state.form_dynform_questions);
                for (const [step, questions] of dynform) {
                    let answered = 0;
                    const filtered_questions = questions.filter(e => e.flags.includes('FLAG_REQUIRED') && e.flags.includes('FLAG_CONSULTANT'));
                    if (filtered_questions.length === 0) continue;
                    for (const question of filtered_questions) {
                        const answer = entry.answers.find(e => e.question_id === question.id);
                        if (answer) {
                            answered++;
                        }
                    }

                    output.push({
                        label: STEPS[step],
                        step: step,
                        completion: Math.round((answered * 100) / filtered_questions.length)
                    })
                }

                return output;
            }
        },
        getDynformCompletionState: () => {
            return (entry: orderedQuestion[]): boolean => {
                for (const question of entry) {
                    if (!question.flags?.includes('FLAG_REQUIRED')) continue
                    if (question.answered === false) return false;
                    if (question.answer === '' || question.answer === null) return false
                }
                return true
            }
        },
        getEntriesText: (state) => {
            return (entry: number): string => {
                const entries = state.dynform_entries.get(entry!) ?? [];
                if (entries.length === 1) {
                    return '1 participant'
                }
                else if (entries.length > 1) {
                    return `${entries.length} participants`
                }
                return 'Aucun participant'
            }
        },
        getInterventionState: () => {
            return (steps: DynformStepData[]): boolean => {
                for (const step of steps) {
                    if (step.completion != 100) {
                        return false
                    }
                }
                return true
            }
        },
    },
    actions: {
        async fetchDynformQuestions(intervention_id: number, category_id: number) {
            const api = useGAEAPI()
            const request_store = useRequestStore()
            this.isFetching = true
            const url = `/api/dynform_question/${intervention_id}/${category_id}`
            const request = api.instance.get<DynformQuestionGET>(url, {
                headers: {
                    "Content-Type": 'application/json',
                    Accept: 'application/json'
                }
            })

            return request.then((response) => {
                this.form_dynform_questions = new Map();
                this.dynform_questions = response.data
                for (const question of response.data) {
                    // skip questions with full day flag if intervention is not full day
                    if (question.flags) {
                        const intervention_request = request_store.getRequests.find(e => e.intervention.id === intervention_id);
                        if (question.flags.includes('FLAG_FULLDAY')) {
                            if (intervention_request && intervention_request.intervention.dayPart !== 'full_day') {
                                continue;
                            }
                            if (this.dynform && this.dynform.intervention_daypart !== 'full_day') {
                                continue
                            }
                        }
                        if (question.flags.includes('FLAG_HALFDAY')) {
                            if (intervention_request && intervention_request.intervention.dayPart !== 'half_day') {
                                continue;
                            }
                            if (this.dynform && this.dynform.intervention_daypart !== 'half_day') {
                                continue
                            }
                        }

                        if (question.flags.includes('FLAG_GROUPS_MULTIPLE')) {
                            if (this.dynform && this.dynform.group_count < 2) {
                                continue;
                            }
                        }

                        if (question.flags.includes('FLAG_GROUPS_SINGLE')) {
                            if (this.dynform && this.dynform.group_count !== 1) {
                                continue;
                            }
                        }

                        if (question.flags.includes('FLAG_PM')) {
                            if (this.dynform && new Date().getHours() < 12) {
                                continue;
                            }
                        }
                    }

                    const item = this.form_dynform_questions.get(question.step);

                    let default_answer: number | string | boolean = '';
                    if (question.type === 8) {
                        default_answer = 1
                    }

                    if (question.type === 9) {
                        default_answer = false
                    }

                    if (question.type === 10) {
                        default_answer = false
                    }

                    if (item === undefined) {
                        this.form_dynform_questions.set(question.step, [
                            { ...question, answer: default_answer, answered: false },
                        ])
                        continue;
                    }
                    item.push({ ...question, answer: default_answer, answered: false })
                }

            }).catch(handleErrorNotification).finally(() => {
                this.isFetching = false
            })
        },
        async postDynformEntry(contract: DynformEntryPOST) {
            const api = useGAEAPI()
            this.isFetching = true
            return api.instance
                .post<DynformEntryPOST>(`/api/dynform_entry`, contract
                )
                .then((response) => {
                    if (response.data.uuid) {
                        const { setCookieValue } = useCookies()

                        const expirationDate = new Date();
                        expirationDate.setDate(expirationDate.getDate() + 1);

                        setCookieValue('user_uuid', response.data.uuid, expirationDate)

                        for (const answer of response.data.answers) {
                            for (const [_, questions] of this.form_dynform_questions) {
                                for (const question of questions) {
                                    if (answer.question_id === question.id || answer.question_id === question.reference) {
                                        if (question.type === 1) {
                                            question.answer = (answer.value.toLowerCase() === 'true')
                                        }
                                        else if (question.type === 8) {
                                            question.answer = parseInt(answer.value)
                                        }
                                        else {
                                            question.answer = answer.value
                                        }
                                        question.answered = true
                                    }

                                }
                            }
                        }

                        toast.success('Vos réponses étés envoyées')
                    }
                })
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        },
        async fetchDynformEntry(user: string) {
            const api = useGAEAPI()
            this.isFetching = true
            return api.instance
                .get<DynformEntryPOST>(`/api/dynform_entry/${user}`, {
                    headers: {
                        "Content-Type": 'application/json',
                        Accept: 'application/json'
                    },
                })
                .then((response) => {

                    if (this.dynform?.intervention_id !== response.data.intervention_id) {
                        const { deleteCookieValue } = useCookies()
                        deleteCookieValue('user_uuid')
                        return;
                    }

                    for (const answer of response.data.answers) {
                        for (const [_, questions] of this.form_dynform_questions) {
                            for (const question of questions) {
                                if (answer.question_id === question.id || answer.question_id === question.reference) {
                                    if (question.type === 1) {
                                        question.answer = (answer.value.toLowerCase() === 'true')
                                    }

                                    if (question.type === 9) {
                                        question.answer = (answer.value.toLowerCase() === 'true')
                                    }

                                    else if (question.type === 8) {
                                        question.answer = parseInt(answer.value)
                                    }
                                    else {
                                        question.answer = answer.value
                                    }
                                    question.answered = true
                                }

                            }
                        }
                    }
                })
                .catch(() => {
                    const { deleteCookieValue } = useCookies()
                    deleteCookieValue('user_uuid')
                    return;
                })
                .finally(() => {
                    this.isFetching = false
                })
        },
        async fetchDynformEntryByIntervention(intervention_id: number) {
            const api = useGAEAPI()
            this.isFetching = true
            return api.instance
                .get<DynformEntryInterventionPOST>(`/api/dynform_entry`, {
                    headers: {
                        "Content-Type": 'application/json',
                        Accept: 'application/json'
                    },
                    params: {
                        intervention_id: intervention_id,
                    }
                })
                .then((response) => {
                    this.dynform_entries = new Map();
                    for (const dynform of response.data) {
                        const item = this.dynform_entries.get(dynform.group_id);
                        if (item === undefined) {
                            this.dynform_entries.set(dynform.group_id, [
                                { ...dynform },
                            ])
                            continue;
                        }
                        item.push({ ...dynform })
                    }

                })
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        },
        async fetchDynform(intervention_id: number, category_id: number, group_id: number) {
            const api = useGAEAPI()
            this.isFetching = true
            return api.instance
                .get<DynformGET>(`/api/dynform/${intervention_id}/${category_id}/${group_id}`, {
                    headers: {
                        "Content-Type": 'application/json',
                        Accept: 'application/json'
                    }
                })
                .then((response) => {
                    this.dynform = response.data
                })
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        },
        async deleteDynformEntry(user: string) {
            const api = useGAEAPI()
            this.isFetching = true
            return api.instance
                .delete(`/api/dynform_entry/${user}`)
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        },

    }
})
