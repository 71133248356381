import type { Skill } from 'types/api/appdata';
import type { APIInterventionPATCHRequest, Intervention, InterventionForm } from 'types/api/intervention'
import { getDateFromTimeModel, getDateYMD } from './service_date';
import { NullIfEmpty, ValueOrUndefined } from './service_api';
import { useInterventionStore } from '@/stores/gae/store_intervention';

type Status = { label: string; color: ApplicationColors, readonly?: boolean }
const mission_statuses: {
  [K in Intervention['missionStatus']]: Status
} = {
  incomplete: { label: 'Incomplète', color: 'danger' },
  in_progress: { label: "Demande d'info", color: 'warning' },
  complete: { label: 'Complète', color: 'success', readonly: true },
  documents_ok: { label: 'Terminée', color: 'success', readonly: true },
  pending_documents: { label: 'Documents en attente', color: 'warning', readonly: true },
  documents_uploaded: { label: 'Documents envoyés', color: 'warning', readonly: true },
}

const client_statuses: {
  [K in Intervention['customerStatus']]: Status
} = {
  option: { label: 'Option', color: 'warning' },
  confirmed: { label: 'Validé', color: 'success' },
  canceled: { label: 'Annulé', color: 'danger' },
}

const consultant_statuses: {
  [K in Intervention['consultantStatus']]: Status
} = {
  none: { label: 'Aucun intervenant', color: 'danger' },
  pending: { label: 'En attente', color: 'warning' },
  confirmed: { label: 'Validé', color: 'success' },
}

export function getMissionStatus(intervention: Intervention) {
  return mission_statuses[intervention.missionStatus]
}

export function getClientStatus(intervention: Intervention) {
  return client_statuses[intervention.customerStatus]
}

export function getConsultantStatus(intervention: Intervention): Status {
  return consultant_statuses[intervention.consultantStatus]
}


export function getMissionStatusArray() {
  const output: SelectOption[] = []
  Object.entries(mission_statuses).forEach(([key, value]) => {
    output.push({ label: value.label, value: key, readonly: value.readonly })
  })
  return output
}

export function getClientStatusArray() {
  const output: SelectOption[] = []
  Object.entries(client_statuses).forEach(([key, value]) => {
    output.push({ label: value.label, value: key, readonly: value.readonly })
  })
  return output
}

export function getConsultantStatusArray() {
  const output: SelectOption[] = []
  Object.entries(consultant_statuses).forEach(([key, value]) => {
    output.push({ label: value.label, value: key, readonly: value.readonly })
  })
  return output
}

export function getSkillCategories(skills?: Skill[]) {
  if (skills == undefined)
    return []
  return [...new Set(skills.map((item) => item.category))]
}

export function getInterventionDateTime(date_str?: string, time?: string) {
  if (date_str && time) {
    const date = new Date(date_str);
    const [hour, minute] = time.split(':')
    date.setHours(parseInt(hour));
    date.setMinutes(parseInt(minute));
    return date;
  }
}

export function getContract(form: InterventionForm): APIInterventionPATCHRequest {
  return {
    complement: NullIfEmpty(form.complement),
    date: getDateYMD(form.date),
    dayPart: NullIfEmpty(form.day_part),
    address: NullIfEmpty(form.address),
    city: NullIfEmpty(form.city),
    postcode: NullIfEmpty(form.postcode),
    consultant: form.consultant?.id ?? null,
    startTime: getDateFromTimeModel(form.start) ?? null,
    endTime: getDateFromTimeModel(form.end) ?? null,
    isDocumentFolderSent: form.sent,
    isRemote: form.remote,
    backer: NullIfEmpty(form.sponsor),
    missionStatus: form.mission_status,
    customer: NullIfEmpty(form.client),
    commercialCustomerContact: NullIfEmpty(form.contact_commercial),
    onSiteContact: NullIfEmpty(form.contact),
    customerStatus: ValueOrUndefined(form.client_status),
    skills: form.skills.map((s) => s.id),
    files: form.files?.map((f) => f.id) ?? undefined,
    referent: form.gae_refered ? parseInt(form.gae_refered) : null,
    salesperson: form.gae_commercial ? parseInt(form.gae_commercial) : null,
    comment: NullIfEmpty(form.comment),
    additionalFees: parseFloat(form.additional_fees),
    zone: form.zone ? parseInt(form.zone) : null,
    ignoredSteps: form.ignored_steps,
    clientEmail: NullIfEmpty(form.client_email),
    childs: form.childs.map(e => e.id)
  }
}

export function resyncRelationships(intervention: Intervention) {
  const store = useInterventionStore()
  store.getInterventions.forEach(month => {
    for (const month_intervention of month.interventions) {
      if (month_intervention.parentIntervention === intervention.id) {
        month_intervention.parentIntervention = undefined;
      }

      if (intervention.childs?.includes(month_intervention.id)) {
        month_intervention.parentIntervention = intervention.id
        // remove all steps except attendance for child intervention
        month_intervention.ignoredSteps = [2, 3, 4, 5, 6, 7]
      }
    }
  })
}