<template>
  <div class="input-checkbox">
    <div class="input-checkbox-checkbox" :class="{ vertical: vertical }">
      <div class="checkbox-wrapper">
        <Check class="icon" color="var(--vc-white)" :size="16" />
        <input
          :id="id"
          :name="`checkbox_${id}`"
          type="checkbox"
          :class="color"
          v-model="input_value"
        />
      </div>
    </div>
    <label :for="id">{{ label }}{{ required ? '*' : '' }}</label>
  </div>
  <div v-if="errors && errors.length > 0" class="input-checkbox__error">
    <AlertCircle :size="16" />
    <p>{{ errors[0].$message }}</p>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { Check } from 'lucide-vue-next'
import { AlertCircle } from 'lucide-vue-next'
import { computed, onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    id: string
    label: string
    vertical?: boolean
    modelValue?: boolean
    required?: boolean
    errors?: ErrorObject[]
    color?: 'primary' | 'dark' | 'success' | 'warning' | 'danger'
  }>(),
  {
    vertical: false,
    color: 'primary'
  }
)

const input_value = computed({
  get: () => {
    return props.modelValue ?? false
  },
  set: (v?: boolean): void => {
    emits('update:modelValue', v)
  }
})
const emits = defineEmits<{
  (e: 'update:modelValue', value?: boolean): void
}>()
</script>

<style scoped lang="scss">
.input-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 5px;
  }
}

.error > div > input {
  border: 1px var(--vc-danger) solid;
}

.input-checkbox > div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;

  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

.input-checkbox > div > p {
  color: rgba(var(--vcrgb-dark), 0.75);
}

input[type='checkbox']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.icon {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.error > input {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

@mixin color($class: primary) {
  $background: var(--vc-#{$class});
  $background-rgb: var(--vcrgb-#{$class});

  input[type='checkbox']:checked.#{$class} {
    background-color: $background;
  }
}

@include color();
@include color(dark);
@include color(success);
@include color(warning);
@include color(danger);
</style>
