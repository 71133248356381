import { useGAEAPI } from '@/composable/gae_api'
import { handleErrorNotification } from '@/services/service_api'
import type { AvailabilityCalendarEvent } from '@/services/service_calendar'
import { getDateYMDsync, getDateYMsync } from '@/services/service_date'
import { defineStore } from 'pinia'
import type { Intervention, InterventionRequest } from 'types/api/intervention'
import { useToast } from 'vue-toast-notification'

type RequestStoreState = {
	requests: InterventionRequest[]
}

export const useRequestStore = defineStore('request', {
	state: (): RequestStoreState => ({
		requests: [],
	}),
	getters: {
		getRequests: (state) => state.requests,
		getAcceptedRequests: (state) => state.requests.filter((r) => r.status === 'accepted' && new Date(r.intervention.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)),
		getPassedInterventions: (state) => state.requests.filter((r) => r.status === 'accepted' && r.intervention.missionStatus === "documents_uploaded" || r.intervention.missionStatus === 'documents_ok').sort((a, b) => new Date(b.intervention.date).getTime() - new Date(a.intervention.date).getTime()),
		getPendingRequests: (state) => state.requests.filter((r) => r.status === 'pending'),
		getPendingDocumentIntervention: (state) => state.requests.filter((r) => r.intervention.missionStatus === 'pending_documents'),
		getRequestByInterventionID: (state) => {
			return (id: number) => {
				return state.requests.find(e => e.intervention.id === id)
			}
		},
		getRequestsEvents: (state): AvailabilityCalendarEvent[] => {
			const map = new Map<string, InterventionRequest[]>();

			const filteredRequests = state.requests.filter((r) => r.status !== 'refused');
			for (const request of filteredRequests) {
				const day = map.get(request.intervention.date);
				if (!day) {
					map.set(request.intervention.date, [request]);
				} else {
					day.push(request);
				}
			}

			const output: AvailabilityCalendarEvent[] = [];
			map.forEach((v, k) => {
				output.push({
					date: k,
					className: 'fc-availability-event',
					extendedProps: {
						requests: v
					}
				})
			})

			return output;
		}
	},
	actions: {
		async fetchRequests() {
			const api = useGAEAPI();
			return api.instance.get<InterventionRequest[]>('/api/intervention_requests', {
				headers: {
					Accept: 'application/json',
				},
			}).then(response => {
				this.requests = response.data;
			});
		},
		find(date: Date) {
			const ymd = getDateYMDsync(date);
			return this.requests.find(e => e.intervention.date === ymd)
		},
		updateInterventions(intervention: Intervention) {
			let i = 0;
			for (const request of this.requests) {
				if (request.intervention.id === intervention.id) {
					this.requests[i].intervention = intervention
				}
				i++;
			}
		},
		accept(request: InterventionRequest) {
			const api = useGAEAPI();
			api.instance.get<InterventionRequest>(`/api/request/${request.id}/accept`).then((response) => {
				const toast = useToast();
				const i = this.requests.findIndex((r) => r.id === request.id);
				this.requests[i] = response.data;
				toast.success(`L'intervention a été acceptée.`);
			}).catch(handleErrorNotification)
		},
		refuse(request: InterventionRequest) {
			const api = useGAEAPI();
			api.instance.get<InterventionRequest>(`/api/request/${request.id}/refuse`).then((response) => {
				const toast = useToast();
				const i = this.requests.findIndex((r) => r.id === request.id);
				this.requests[i] = response.data;
				toast.success(`L'intervention a été refusée.`);
			}).catch(handleErrorNotification)
		}
	}
})

