<template>
  <div>
    <CommonButton
      @click="showGroup = !showGroup"
      filled
      :icon="showGroup ? 'Undo2' : 'Plus'"
      class="button"
      size="medium"
      color="primary"
    >
      {{ showGroup ? 'Annuler' : 'Ajouter un groupe' }}
    </CommonButton>
  </div>
  <div v-if="showGroup" class="add-group">
    <LayoutTwoColumn style="margin-bottom: 12px">
      <InputTime id="group-start" label="Debut" v-model="startTime" />
      <InputTime id="group-start" label="Fin" v-model="endTime" />
    </LayoutTwoColumn>
    <InputCommon
      style="margin-bottom: 12px"
      id="group-count"
      label="Nombre de participants"
      v-model="intern_count"
    />
    <CommonIconButton
      :disabled="intern_count === ''"
      @click="addGroup"
      label="Ajouter le groupe"
      icon="Check"
      squared
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CommonButton from '../common/CommonButton.vue'
import InputTime from '../common/input/InputTime.vue'
import type { TimeModel } from '@vuepic/vue-datepicker'
import CommonIconButton from '../common/CommonIconButton.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import type { Intervention } from 'types/api/intervention'
import { useToast } from 'vue-toast-notification'
import InputCommon from '../common/input/InputCommon.vue'
import LayoutTwoColumn from '../layout/LayoutTwoColumn.vue'

const showGroup = ref(false)
const startTime = ref<TimeModel>()
const endTime = ref<TimeModel>()
const intern_count = ref<string>('0')
const intervention_store = useInterventionStore()
const toast = useToast()

const emit = defineEmits(['groupAdded'])

const props = defineProps<{
  intervention: Intervention
}>()

function addGroup() {
  if (startTime.value === undefined || endTime.value === undefined) {
    toast.error('Renseignez des heures valides.')
    return
  }

  if (intern_count.value === '') {
    intern_count.value = '0'
  }

  const start = `${startTime.value?.hours}:${startTime.value?.minutes}`
  const end = `${endTime.value?.hours}:${endTime.value?.minutes}`
  intervention_store
    .addInterventionGroup(props.intervention.id, start, end, parseInt(intern_count.value))
    .then((response) => {
      if (response) {
        emit('groupAdded', response.data)
        startTime.value = undefined
        endTime.value = undefined
        intern_count.value = '0'
      }
      showGroup.value = false
    })
}
</script>

<style scoped>
.add-group {
  width: 100%;
}
</style>
