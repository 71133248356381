<template>
  <div class="composite-dynform-tab">
    <template v-if="security.isAdmin && intervention.missionStatus === 'documents_uploaded'">
      <CommonParagraph class="mb-20" title="Valider les documents">
        <div class="client-emails">
          <p>L'email sera envoyé à :</p>
          <CommonChip color="dark" filled v-for="email in intervention.clientEmail?.split(',')">{{
            email
          }}</CommonChip>
        </div>
        <CommonButton
          @click="validate"
          filled
          color="success"
          icon="Check"
          class="send"
          :disabled="intervention.clientEmail === ''"
        >
          Envoyer le rapport au client et terminer la mission
        </CommonButton>
      </CommonParagraph>
    </template>
    <CommonParagraph v-if="user_uuid" title="Formulaires de l'intervention">
      <div
        class="composite-dynform-documents"
        v-if="
          security.isAdmin &&
          (intervention.parentIntervention === undefined ||
            intervention.parentIntervention === null)
        "
      >
        <CommonButton
          v-if="
            [1, 2, 5, 6].includes(category()) &&
            !intervention.ignoredSteps.includes(1) &&
            intervention.childs!.length === 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(3)"
        >
          Feuille de présence
        </CommonButton>
        <CommonButton
          v-if="
            [1, 2, 5, 6].includes(category()) &&
            !intervention.ignoredSteps.includes(1) &&
            intervention.childs!.length > 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(12)"
        >
          Feuille de présence
        </CommonButton>
        <CommonButton
          v-if="
            [1, 2, 4].includes(category()) &&
            !intervention.ignoredSteps.includes(2) &&
            intervention.childs!.length === 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(8)"
        >
          Questionnaires Satisfaction
        </CommonButton>
        <CommonButton
          v-if="
            [1, 2, 4].includes(category()) &&
            !intervention.ignoredSteps.includes(2) &&
            intervention.childs!.length > 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(13)"
        >
          Questionnaires Satisfaction
        </CommonButton>
        <CommonButton
          v-if="
            [1].includes(category()) &&
            !intervention.ignoredSteps.includes(3) &&
            intervention.childs!.length === 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(9)"
        >
          Évaluation Acquis
        </CommonButton>
        <CommonButton
          v-if="
            [1].includes(category()) &&
            !intervention.ignoredSteps.includes(3) &&
            intervention.childs!.length > 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(14)"
        >
          Évaluation Acquis
        </CommonButton>
        <CommonButton
          v-if="
            [1].includes(category()) &&
            !intervention.ignoredSteps.includes(7) &&
            intervention.childs!.length === 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(7)"
        >
          Attestations
        </CommonButton>
        <CommonButton
          v-if="[1].includes(category()) && !intervention.ignoredSteps.includes(1)"
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(2)"
        >
          Tour de table
        </CommonButton>
        <CommonButton
          v-if="[1, 2, 3, 4].includes(category()) && !intervention.ignoredSteps.includes(5)"
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(4)"
        >
          Évaluation Intervenant
        </CommonButton>
        <CommonButton
          v-if="!intervention.ignoredSteps.includes(6) && intervention.childs!.length === 0"
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(5)"
        >
          Rapport client
        </CommonButton>
        <CommonButton
          v-if="!intervention.ignoredSteps.includes(6) && intervention.childs!.length > 0"
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(10)"
        >
          Rapport client
        </CommonButton>
        <CommonButton
          v-if="
            [1].includes(category()) &&
            !intervention.ignoredSteps.includes(7) &&
            intervention.childs!.length > 0
          "
          color="dark"
          size="small"
          icon="Download"
          @click="getPDF(11)"
        >
          Attestations
        </CommonButton>
      </div>
    </CommonParagraph>
    <InputSelect
      v-if="false && props.intervention.serviceCategories.length !== 1"
      id="category-select"
      label="Catégorie selectionnée"
      :options="
        props.intervention.serviceCategories.map((e) => ({
          label: e.name,
          value: e.id.toString()
        }))
      "
      v-model="store.selected_category"
      @update:model-value="(v) => store.fetchDynformQuestions(intervention.id, v)"
    />
    <template v-if="![3].includes(category())">
      <div class="add-group">
        <CompositeAddGroup :intervention="intervention" @groupAdded="addGroup" />
        <CommonChip size="large" color="dark" transparent>
          Participants totaux : {{ getAllFilledForms() }}</CommonChip
        >
      </div>
      <template v-if="store.selected_category !== ''">
        <CompositeGroupCard
          v-for="(group, key) in groups"
          :deletable="groups.length > 1"
          :key="key"
          :group="group"
          :group_key="key"
          :entries="store.dynform_entries.get(group.id!) ?? []"
          :group_count="props.intervention.groups.length"
          :intern_count="group.intern_count ?? 0"
          ref="groups_components"
          @delete-group="deleteGroup"
          @edit-group="editGroup"
        />
      </template>
    </template>
    <template
      v-if="
        store.selected_category !== undefined &&
        groups &&
        store.dynform &&
        (intervention.parentIntervention === undefined || intervention.parentIntervention === null)
      "
    >
      <CompositeForm
        @loaded="onChildLoaded"
        @submit="onChildLoaded"
        :intervention_id="intervention.id"
        :category_id="parseInt(store.selected_category)"
        isConsultantForm
        :group="2"
      />
      <p v-if="getTraineesCompletion() === false">
        <FileWarning color="var(--vc-danger)" /> <strong>Attention: </strong> Les documents des
        stagiaires ne sont pas complets.
      </p>
      <CommonButton
        filled
        icon="Check"
        :color="getTraineesCompletion() ? 'success' : 'warning'"
        :loading="file_store.isFetching"
        :disabled="
          intervention.missionStatus === 'documents_uploaded' ||
          intervention.missionStatus === 'documents_ok'
        "
        @click="submitFiles"
      >
        Envoyer les documents
      </CommonButton>
    </template>
    <div v-else-if="fetching" class="composite-dynform-tab-loader">
      <CommonLoader style="color: var(--vc-dark)" />
      <p>Chargement des données de formulaire en cours...</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useDynformStore } from '@/stores/gae/store_dynform'
import type { Intervention, InterventionGroup } from 'types/api/intervention'
import InputSelect from '../common/input/InputSelect.vue'
import CompositeGroupCard from './CompositeGroupCard.vue'
import CompositeAddGroup from './CompositeAddGroup.vue'
import CompositeForm from './CompositeForm.vue'
import CommonLoader from '../common/CommonLoader.vue'
import { useCookies } from '@/composable/cookies'
import CommonButton from '../common/CommonButton.vue'
import CommonParagraph from '../common/CommonParagraph.vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import { useFile } from '@/stores/gae/store_file'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CommonChip from '../common/CommonChip.vue'
import { FileWarning } from 'lucide-vue-next'

const props = defineProps<{
  intervention: Intervention
}>()

const security = useSecurityStore()
const groups = ref<InterventionGroup[]>([])
const fetching = ref(true)
const cookie = useCookies()
const file_store = useFile()
const isChildLoaded = ref(false)
const user_uuid = ref<string | null>(null)
const intervention_store = useInterventionStore()

const store = useDynformStore()

function addGroup(group: InterventionGroup) {
  groups.value!.push(group)

  sortGroups()
}

function deleteGroup(id: number) {
  const index = groups.value?.findIndex((e) => e.id === id)
  if (index !== undefined && index !== -1) {
    groups.value?.splice(index, 1)
  }
  sortGroups()
}

function onChildLoaded() {
  user_uuid.value = getCookieValue('user_uuid').value
  isChildLoaded.value = true
}

function editGroup(group: InterventionGroup) {
  const index = groups.value?.findIndex((e) => e.id === group.id)
  if (index !== undefined && index !== -1) {
    groups.value?.splice(index, 1)
    groups.value.push(group)
  }
  sortGroups()
}

function sortGroups() {
  groups.value = groups.value.sort((a, b) => {
    const dateA = new Date(a.start).getTime() // Extract timestamp
    const dateB = new Date(b.start).getTime() // Extract timestamp
    return dateA - dateB
  })
}

function validate() {
  if (confirm('Confirmer la validité des documents ?')) {
    file_store.validate(props.intervention).then(() => {
      intervention_store.refresh()
    })
  }
}

const { getCookieValue, deleteCookieValue } = useCookies()

onMounted(async () => {
  deleteCookieValue('user_uuid')
  store.dynform = undefined
  store.intervention_id = props.intervention.id
  store.dynform_entries = new Map()

  groups.value = props.intervention.groups
  if (props.intervention.serviceCategories.length === 1) {
    store.selected_category = props.intervention.serviceCategories[0].id.toString()
  }

  // @todo Check if this can reach unintented behaviour
  if (store.selected_category && groups.value[0] && groups.value[0].id) {
    await store.fetchDynformQuestions(props.intervention.id, parseInt(store.selected_category))
    await store.fetchDynform(
      props.intervention.id,
      parseInt(store.selected_category),
      groups.value[0].id
    )
    await store.fetchDynformEntryByIntervention(props.intervention.id)
  }
  fetching.value = false
})

function getPDF(type: number) {
  if ([4, 5, 6].includes(category())) {
    window.open(
      `${
        import.meta.env.VITE_GAE_API_URL
      }/dynform_entry/0192ae8c-b935-7fb1-808c-31d03cd51419/documents/${type}?intervention_id=${
        props.intervention.id
      }`
    )
    return
  }

  const user = cookie.getCookieValue('user_uuid')
  if (user.value) {
    window.open(
      `${import.meta.env.VITE_GAE_API_URL}/dynform_entry/${
        user.value
      }/documents/${type}?intervention_id=${props.intervention.id}`
    )
  }
}

function category() {
  return props.intervention.serviceCategories[0].id
}

async function submitFiles() {
  if (getTraineesCompletion() === false) {
    if (
      !confirm(
        "Il manque des informations participant, êtes-vous sûr de valider l'envoi des documents ?"
      )
    ) {
      return
    }
  }

  if (confirm("Confirmer l'envoi des documents ?")) {
    file_store.sendInterventionDocuments(props.intervention)
  }
}

function getTraineesCompletion() {
  for (const [_, entries] of store.dynform_entries) {
    for (const entry of entries) {
      if (entry.isConsultant) continue
      if (!entry.state) continue
      for (const state_step of entry.state.steps) {
        if (state_step.incomplete_questions > 0) {
          return false
        }
      }
    }
  }
  return true
}

function getAllFilledForms() {
  let count = 0
  store.dynform_entries.forEach((values) => {
    count += values.filter((entry) => !entry.isConsultant).length
  })
  return count
}
</script>

<style scoped>
.composite-dynform-tab {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.composite-dynform-documents {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.composite-dynform-tab-loader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.client-emails {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.add-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}
</style>
