<template>
  <div class="input-rating">
    <p class="input-rating-label">{{ label }}</p>
    <div class="input-rating-numbers">
      <div v-for="i in numbers" class="input-rating-number" :key="i">
        <Star
          color="var(--vc-primary)"
          @click="emits('update:modelValue', i)"
          fill="var(--vc-primary)"
          v-if="i - 1 < (input_value ?? 0)"
        />
        <Star color="var(--vc-dark)" @click="emits('update:modelValue', i)" v-else />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Star } from 'lucide-vue-next'
import { computed } from 'vue'

const props = defineProps<{
  id: string
  label: string
  numbers: number
  required?: boolean
  modelValue?: number
}>()

const input_value = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: number): void => {
    emits('update:modelValue', v)
  }
})

const emits = defineEmits<{
  (e: 'update:modelValue', v?: number): void
}>()
</script>

<style scoped>
.input-rating-numbers {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.input-rating-number {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 5px;
}

.input-rating-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--vc-dark);
}
</style>
