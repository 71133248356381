<template>
  <div class="container">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <div class="radio-wrapper" :class="{ vertical: vertical }">
      <div
        class="input-radio"
        :class="{ error: errors && errors.length > 0 }"
        v-for="(option, key) in options"
        :key="key"
      >
        <div>
          <Check
            class="icon"
            color="var(--vc-white)"
            :size="16"
            v-if="selected_value === option.value"
          />
          <input
            :id="id"
            :name="id"
            type="radio"
            :class="color"
            v-model="selected_value"
            :value="option.value"
            :required="required"
          />
          <p>{{ option.label }}</p>
        </div>
      </div>
    </div>
    <div v-if="errors && errors.length > 0" class="input-radio__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { Check } from 'lucide-vue-next'
import { AlertCircle } from 'lucide-vue-next'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    id: string
    label: string
    vertical?: boolean
    modelValue?: string
    required?: boolean
    errors?: ErrorObject[]
    color?: 'primary' | 'dark' | 'success' | 'warning' | 'danger'
    options: SelectOption[]
  }>(),
  {
    vertical: false,
    color: 'primary'
  }
)

const selected_value = computed({
  get: () => props.modelValue,
  set: (v?: string): void => {
    emits('update:modelValue', v)
  }
})

const emits = defineEmits<{
  (e: 'update:modelValue', value?: string | number): void
}>()
</script>

<style scoped lang="scss">
.radio-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
}

.vertical {
  flex-direction: column;
  gap: 10px;
}
.input-radio {
  display: flex;
  align-items: center;
  gap: 10px;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 5px;
  }
}

.error > div > input {
  border: 1px var(--vc-danger) solid;
}

.input-radio > div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;

  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

.input-radio > div > p {
  color: rgba(var(--vcrgb-dark), 0.75);
}

input[type='radio']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
}

.icon {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.container > label {
  display: block;
  margin-bottom: 15px;
}

.error > input {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

@mixin color($class: primary) {
  $background: var(--vc-#{$class});
  $background-rgb: var(--vcrgb-#{$class});

  input[type='checkbox']:checked.#{$class} {
    background-color: $background;
  }
}

@include color();
@include color(dark);
@include color(success);
@include color(warning);
@include color(danger);
</style>
