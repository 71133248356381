<template>
  <LayoutContainer center :maxWidth="600" v-if="store.dynform">
    <div class="header">
      <img :src="LogoGAE" alt="" />
      <h2>{{ store.dynform.intervention_name }}</h2>
      <div>
        <LabelDate :date="store.dynform.date" size="medium" />
        <CommonIconLabel size="medium" icon="Clock">
          {{ store.dynform.startTime }} - {{ store.dynform.endTime }}
        </CommonIconLabel>
      </div>
      <p>
        Veuillez remplir les formulaires ci dessous aux moments indiqués par vos intervenants. Les
        formulaires ne peuvent être remplis que sur un seul appareil.
      </p>
    </div>
    <CompositeForm
      v-if="store.dynform && intervention_id && category && group"
      :dynform="store.dynform"
      :intervention_id="intervention_id"
      :group="group"
      :category_id="category"
      :showHeader="store.dynform.group_count > 1"
      ref="form"
    />
  </LayoutContainer>
</template>

<script setup lang="ts">
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import CompositeForm from '@/components/composite/CompositeForm.vue'
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'
import LogoGAE from '@/assets/logo-GAE-color.svg'
import LabelDate from '@/components/composite/label/LabelDate.vue'

import { useDynformStore } from '@/stores/gae/store_dynform'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import { useCookies } from '@/composable/cookies'

const route = useRoute()
const group = ref<number>()
const category = ref<number>()
const intervention_id = ref<number>()

const store = useDynformStore()

onMounted(() => {
  group.value = parseInt(route.query.group as string)
  category.value = parseInt(route.query.category as string)
  intervention_id.value = parseInt(route.query.intervention_id as string)
  if (route.query.entry) {
    const { setCookieValue } = useCookies()
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 1)
    setCookieValue('user_uuid', route.query.entry as string, expirationDate)
  }

  store.fetchDynform(intervention_id.value, category.value, group.value)
})
</script>

<style scoped>
img {
  width: 100%;
  max-width: 300px;
  height: auto;
  box-sizing: border-box;
  display: block;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.header > div {
  display: flex;
  gap: 20px;
}

.header > p {
  max-width: 600px;
}

.submit {
  margin: auto;
}

@media screen and (max-width: 640px) {
  .header > h2 {
    font-size: 28px;
    text-align: center;
  }
}
</style>
