<template>
  <ModalConsultant :consultant="consultant" ref="modal" />
  <CommonCard @click="() => modal?.open()" class="consultant-wrapper">
    <div class="consultant-container">
      <div class="skills">
        <CommonChip
          :size="v_mobile ? 'small' : 'medium'"
          v-for="skill_category in [...new Set(consultant.skills?.map((item) => item.category))]"
          :key="skill_category"
          transparent
          color="dark"
          >{{ skill_category }}</CommonChip
        >
      </div>
      <div class="information-wrapper">
        <div :class="['right-group', { 'mobile-margin': v_mobile }]">
          <h5>{{ consultant.acronym }} - {{ consultant.firstname }} {{ consultant.lastname }}</h5>
          <p>{{ consultant.city }} {{ consultant.postcode }}</p>
        </div>
        <div class="description-wrapper">
          <p class="description">
            <span>{{ consultant.totalHours }}h</span> de formation réalisée sur la dernière année
            civile
          </p>
        </div>
      </div>
      <div class="send" v-if="false">
        <CommonIcon class="common-button-icon" name="Send" :stroke-width="1.5" :size="16" />
        <div class="text">
          <p>A envoyer</p>
          <p class="bold">Pochette + matériel</p>
        </div>
      </div>
    </div>
    <CommonIconButton
      v-if="!v_mobile"
      color="primary"
      icon="ArrowRight"
      class="icon"
      @click="emits('open')"
    />
  </CommonCard>
</template>

<script setup lang="ts">
import CommonCard from '@/components/layout/LayoutCard.vue'
import CommonIconButton from '@/components/common/CommonIconButton.vue'
import CommonChip from '@/components/common/CommonChip.vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import CommonIcon from '@/components/common/CommonIcon.vue'
import type { Consultant } from 'types/api/consultant'
import ModalConsultant from './modal/ModalConsultant.vue'
import { ref } from 'vue'

const breakpoint = useBreakpoints(breakpointsTailwind)
const v_mobile = breakpoint.smallerOrEqual('sm')
const modal = ref<InstanceType<typeof ModalConsultant>>()

const emits = defineEmits(['open'])

defineProps<{
  consultant: Consultant
}>()
</script>

<style scoped>
.consultant-wrapper {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.skills {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  width: fit-content;
}

.consultant-wrapper:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  border-color: rgba(var(--vcrgb-dark), 0.5);
}

.information-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.right-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 350px;
}

.right-group > p {
  font-size: 15px;
  color: rgba(var(--vcrgb-dark), 0.6);
}

.consultant-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.description {
  text-align: left;
  line-height: 110%;
  font-family: 'Roboto';
  opacity: 0.75;
  color: var(--vc-dark);
}

.description-wrapper {
  box-sizing: border-box;
  width: 300px;
  padding-right: 100px;
}

.description > span {
  color: var(--vc-success);
}

.icon {
  margin-left: auto;
}

.mobile-margin {
  margin-bottom: 10px;
  max-width: 300px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.send {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: var(--vc-primary);
}

.bold {
  font-weight: 500;
}

@media (max-width: 1024px) {
  .description-wrapper {
    padding: 0;
  }

  .right-group {
    width: 350px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .icon {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

@media (max-width: 640px) {
  .description {
    margin-bottom: 10px;
  }
  .right-group {
    width: unset;
    margin-bottom: 10px;
  }

  .description-wrapper {
    box-sizing: border-box;
    width: unset;
  }
}
</style>
