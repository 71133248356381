<template>
  <LayoutModalMessage ref="modal_confirm" v-model="confirm_open" @confirm="remove" />
  <LayoutModal :can-close="!confirm_open" @on-closed="() => (edit = false)" ref="modal">
    <CompositeRequestConfirm
      v-if="!security.isAdmin && request?.status === 'pending'"
      class="request-confirm"
      :request="request"
    />
    <LayoutModalContent class="modal-content">
      <div class="header">
        <CommonReturn @click="() => (edit ? (edit = false) : modal?.close())" text="Retour" />
        <div class="actions">
          <CommonIconButton
            v-if="!edit"
            color="dark"
            hover="Export PDF"
            squared
            icon="FileUp"
            @click="() => intervention_store.export(intervention)"
          />
          <template v-if="security.isAdmin">
            <CommonIconButton
              v-if="!edit"
              color="dark"
              hover="Editer"
              squared
              icon="FileEdit"
              @click="load"
            />
            <CommonIconButton
              v-if="!edit"
              color="dark"
              hover="Dupliquer"
              squared
              icon="Copy"
              @click="emits('duplicate', intervention)"
            />
            <CommonIconButton
              color="danger"
              hover="Supprimer"
              squared
              icon="Trash2"
              @click="() => modal_confirm?.open()"
            />
          </template>
        </div>
      </div>
      <div class="categories">
        <CommonChip
          v-for="category of getSkillCategories(intervention.skills)"
          :key="category"
          size="large"
          transparent
          color="dark"
        >
          {{ category }}
        </CommonChip>
        <CommonChip
          v-if="intervention.parentIntervention"
          :key="`parent_${intervention.parentIntervention}`"
          size="large"
          transparent
          color="success"
        >
          Intervention parente: {{ getParent() }}
        </CommonChip>
        <CommonChip
          v-if="intervention.childs && intervention.childs.length > 0"
          size="large"
          transparent
          color="success"
        >
          {{ intervention.childs.length }} Intervention liée(s)
        </CommonChip>
      </div>
      <div class="name">
        <h2>{{ getInterventionName() }}</h2>
        <p v-if="intervention.complement">{{ intervention.complement }}</p>
        <p v-if="intervention.consultant">Intervenant : {{ intervention.consultant.name }}</p>
      </div>
      <div class="status">
        <div>
          <CommonChip v-if="intervention.customer" size="large" filled color="dark">
            {{ intervention.customer }}
          </CommonChip>
          <CommonChip v-if="intervention.referent" size="large" color="dark">
            {{ intervention.referent?.firstname }}
            {{ intervention.referent?.lastname }}
          </CommonChip>
          <CommonChip v-if="intervention.zone" size="large" color="dark">
            {{ intervention.zone.level === 0 ? 'Distanciel' : 'Zone ' + intervention.zone.level }}
          </CommonChip>
        </div>
        <CommonChip size="large" transparent :color="getMissionStatus(intervention).color">
          {{ getMissionStatus(intervention).label }}
        </CommonChip>
      </div>
      <div class="informations">
        <LabelDate :size="v_mobile ? 'small' : 'medium'" :date="intervention.date" />
        <CommonIconLabel
          v-if="intervention.dayPart"
          :size="v_mobile ? 'small' : 'medium'"
          icon="CalendarClock"
        >
          {{ intervention.dayPart === 'full_day' ? 'Journée' : 'Demi-Journée' }}</CommonIconLabel
        >
        <LabelTime
          v-if="intervention.startTime && intervention.endTime"
          :size="v_mobile ? 'small' : 'medium'"
          :start="intervention.startTime"
          :end="intervention.endTime"
        />
        <LabelLocalization
          v-if="intervention.address || intervention.city || intervention.postcode"
          :size="v_mobile ? 'small' : 'medium'"
          :localization="
            makeAddressString(intervention.address, intervention.city, intervention.postcode)
          "
        />
      </div>
      <LayoutTabs ref="tabs" :disabled="edit" :icons="['Contact', 'Book']">
        <template #détails>
          <template v-if="!security.isAdminReadonly">
            <CommonButton @click="addGoogle" color="primary" size="small" filled class="mb-10">
              Ajouter la mission à mon agenda google
            </CommonButton>
            <CommonButton @click="addIcal" color="primary" size="small" filled class="mb-30">
              Ajouter la mission à mon agenda outlook
            </CommonButton>
          </template>
          <div v-if="edit">
            <FormIntervention ref="form" class="mb-40" />
            <CommonButton
              :loading="intervention_store.isFeching"
              class="submit mb-40"
              filled
              icon="Save"
              @click="submit"
            >
              Enregistrer les modifications
            </CommonButton>
          </div>
          <div
            v-else-if="
              intervention.missionStatus != 'pending_documents' || security.isAdminReadonly
            "
          >
            <LayoutThreeColumn v-if="security.isAdmin || security.isAdminReadonly" class="mb-20">
              <CompositeLabelCard
                :label="getMissionStatus(intervention).label"
                :color="getMissionStatus(intervention).color"
              >
                <h5>Statut Mission</h5>
              </CompositeLabelCard>
              <CompositeLabelCard
                :label="getConsultantStatus(intervention).label"
                :color="getConsultantStatus(intervention).color"
              >
                <h5>Statut Intervenant</h5>
              </CompositeLabelCard>
              <CompositeLabelCard
                :label="getClientStatus(intervention).label"
                :color="getClientStatus(intervention).color"
              >
                <h5>Statut Client</h5>
              </CompositeLabelCard>
            </LayoutThreeColumn>
            <LayoutTwoColumn v-else class="mb-20">
              <CompositeLabelCard
                :label="getClientStatus(intervention).label"
                :color="getClientStatus(intervention).color"
              >
                <h5>Statut Client</h5>
              </CompositeLabelCard>
            </LayoutTwoColumn>
            <ModalInterventionFiles
              title="Anciens documents de la mission"
              v-if="
                security.isAdmin &&
                (intervention.missionStatus === 'documents_uploaded' ||
                  intervention.missionStatus === 'documents_ok' ||
                  intervention.missionStatus === 'complete')
              "
              :readonly="
                intervention.missionStatus === 'documents_ok' ||
                intervention.missionStatus === 'documents_uploaded'
              "
              :intervention="intervention"
            />
            <CommonParagraph v-if="intervention.backer" class="mb-20" title="Commanditaire">
              <p>
                {{ intervention.backer }}
              </p>
            </CommonParagraph>
            <CommonParagraph class="mb-20" title="Contacts">
              <LayoutTwoColumn>
                <CompositeTitleCard title="Contact commercial">
                  {{ intervention.commercialCustomerContact ?? 'Non renseigné.' }}
                </CompositeTitleCard>
                <CompositeTitleCard title="Contact sur place">
                  {{ intervention.onSiteContact ?? 'Non renseigné.' }}
                </CompositeTitleCard>
              </LayoutTwoColumn>
            </CommonParagraph>
            <CommonParagraph
              v-if="intervention.comment"
              class="intervention-comment mb-20"
              title="Organisation"
            >
              <div v-html="intervention.comment"></div>
            </CommonParagraph>
            <CommonParagraph
              class="mb-20"
              title="Documents"
              v-if="intervention.files && intervention.files.length > 0"
            >
              <LayoutTwoColumn>
                <CommonFile
                  v-for="file of intervention.files"
                  :key="file.id"
                  :file="file.filename"
                  :size="file.size"
                  :downloadUrl="file.downloadUrl"
                />
              </LayoutTwoColumn>
            </CommonParagraph>
            <CommonButton
              v-if="all_ignored && intervention.missionStatus !== 'documents_ok'"
              @click="validate"
              filled
              color="success"
              icon="Check"
              style="width: 100%"
            >
              Terminer la mission
            </CommonButton>
          </div>
          <div v-else></div>
          <div
            v-if="
              security.isAdmin &&
              !edit &&
              (intervention.missionStatus === 'in_progress' ||
                intervention.missionStatus === 'incomplete')
            "
          >
            <CommonButton
              @click="confirmIntervention"
              icon="Check"
              class="mt-30 mb-10"
              style="width: 100%"
              filled
              color="success"
              :disabled="
                intervention.consultant === undefined || intervention.customerStatus !== 'confirmed'
              "
              :loading="intervention_store.isFeching"
            >
              Confirmer la mission
            </CommonButton>
            <p>*Confirmer la mission enverra la fiche mission (PDF) par mail à l'intervenant.</p>
          </div>
        </template>
        <template
          v-if="
            intervention.missionStatus != 'incomplete' &&
            intervention.missionStatus != 'in_progress'
          "
          #formulaires
        >
          <CompositeDynformTab v-if="all_ignored === false" :intervention="intervention" />
          <p v-else>Aucun formulaire n'est requis pour cette formation.</p>
        </template>
      </LayoutTabs>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonChip from '@/components/common/CommonChip.vue'
import CommonIconButton from '@/components/common/CommonIconButton.vue'
import CommonReturn from '@/components/common/CommonReturn.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import CommonFile from '@/components/common/CommonFile.vue'
import { nextTick, ref } from 'vue'
import CompositeLabelCard from '../CompositeLabelCard.vue'
import LayoutThreeColumn from '@/components/layout/LayoutThreeColumn.vue'
import CommonParagraph from '@/components/common/CommonParagraph.vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import LayoutModalMessage from '@/components/layout/LayoutModalMessage.vue'
import {
  getConsultantStatus,
  getContract,
  getMissionStatus,
  getSkillCategories
} from '@/services/service_intervention'
import type { Intervention, InterventionRequest } from 'types/api/intervention'
import FormIntervention from '../forms/FormIntervention.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import { getDateYMD, makeAddressString } from '@/services/service_date'
import LayoutTwoColumn from '@/components/layout/LayoutTwoColumn.vue'
import CompositeTitleCard from '../CompositeTitleCard.vue'
import LabelDate from '../label/LabelDate.vue'
import LabelTime from '../label/LabelTime.vue'
import LabelLocalization from '../label/LabelLocalization.vue'
import { getClientStatus } from '@/services/service_intervention'
import { useSecurityStore } from '@/stores/gae/store_security'
import CompositeRequestConfirm from '../CompositeRequestConfirm.vue'
import ModalInterventionFiles from './ModalInterventionFiles.vue'
import { useToast } from 'vue-toast-notification'
import {
  generate_calendar_event,
  generate_calendar_event_from_intervention,
  generate_ical_event
} from '@/services/service_google'
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'
import LayoutTabs from '@/components/layout/LayoutTabs.vue'
import CompositeDynformTab from '../CompositeDynformTab.vue'
import { useFile } from '@/stores/gae/store_file'

const props = defineProps<{
  intervention: Intervention
  request?: InterventionRequest
}>()

const emits = defineEmits<{
  (e: 'duplicate', value: Intervention): void
}>()

const intervention_store = useInterventionStore()
const security = useSecurityStore()
const file_store = useFile()
const modal = ref<InstanceType<typeof LayoutModal>>()
const modal_confirm = ref<InstanceType<typeof LayoutModalMessage>>()
const breakpoint = useBreakpoints(breakpointsTailwind)
const v_mobile = breakpoint.smallerOrEqual('sm')
const edit = ref(false)
const form = ref<InstanceType<typeof FormIntervention>>()
const confirm_open = ref(false)
const tabs = ref<InstanceType<typeof LayoutTabs>>()
const customer_email = ref<string>('')
const all_ignored = ref<boolean>(allIgnored())

function addGoogle() {
  const options = generate_calendar_event_from_intervention(props.intervention)
  const url = generate_calendar_event(options)
  window.open(url, '_blank')
}

function addIcal() {
  const options = generate_calendar_event_from_intervention(props.intervention)
  generate_ical_event(options)
}

function getInterventionName() {
  if (props.intervention.skills.length === 0) {
    return 'Compétence(s) à définir...'
  }

  if (props.intervention.skills.length > 2) {
    return props.intervention.skills.map((item) => item.shortname).join(', ')
  }
  return props.intervention.skills.map((item) => item.name).join(', ')
}

function confirmIntervention(p0: string) {
  const confirm = window.confirm("Confirmer l'intervention ?")
  if (!confirm) return

  intervention_store.complete(props.intervention).then(() => {
    modal.value?.close()
  })
}

function load() {
  tabs.value?.setTab(0)
  edit.value = true
  nextTick(() => {
    if (form.value) {
      form.value.load(props.intervention)
    }
  })
}

function open() {
  if (modal.value) {
    modal.value.open()
  }
}

async function submit() {
  if (form.value) {
    const validation = await form.value.getValidation()
    if (!validation) {
      const toast = useToast()
      toast.error('Certains champs sont invalides.')
      return
    }

    const contract = getContract(form.value.getData())
    if (contract) {
      try {
        await intervention_store.patch(props.intervention.id, contract).then(() => {
          edit.value = false
        })
        all_ignored.value = allIgnored()
      } catch (e) {
        return
      }
    }
  }
}

function remove() {
  intervention_store.delete(props.intervention).then(() => {
    modal.value?.close(true)
  })
}

function getParent() {
  if (props.intervention.parentIntervention) {
    const intervention = intervention_store.getInterventionByID(
      props.intervention.parentIntervention
    )
    if (intervention) {
      return new Date(intervention?.date).toLocaleDateString('fr')
    }
  }
}

function allIgnored() {
  const requiredSteps = [1, 2, 3, 4, 6, 7]
  const ignoredSteps = props.intervention.ignoredSteps
  if (!ignoredSteps) return false

  const containsAllValues = requiredSteps.every((step) => ignoredSteps.includes(step))
  return containsAllValues
}

function validate() {
  if (confirm('Confirmer la fin de la mission ?')) {
    file_store.validate(props.intervention).then(() => {
      intervention_store.refresh()
    })
  }
}

defineExpose({ open })
</script>

<style scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 15px;
}

.actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.name {
  margin-bottom: 20px;
}

.name > p {
  margin-top: 2px;
  margin-bottom: 10px;
  font-style: italic;
  color: rgba(var(--vcrgb-dark), 0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-content {
  margin-top: 46px;
  margin-bottom: 120px;
}

.categories {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.status {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.status > div {
  display: flex;
  gap: 10px;
}

.informations {
  display: flex;
  column-gap: 30px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.submit {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .name {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .informations {
    gap: 15px;
  }
}

.request-confirm {
  position: fixed;
  z-index: 10;
  bottom: 0;
}
</style>
