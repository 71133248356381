<template>
  <p class="label">{{ label }}{{ required ? '*' : '' }} :</p>
  <canvas :style="{ width: props.width, height: props.height }" ref="signatureCanvas"></canvas>
  <div class="button-wrapper">
    <CommonButton size="small" @click.prevent="signaturePad.clear()">
      <CommonIcon name="RotateCcw" :stroke-width="2" />
    </CommonButton>
    <CommonButton size="small" v-if="props.undo" @click="undoAction()">
      <CommonIcon :stroke-width="2" name="Undo" />Retour
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import SignaturePad from 'signature_pad'
import CommonButton from '../common/CommonButton.vue'
import CommonIcon from '../common/CommonIcon.vue'
import { ref, onMounted } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    width?: string
    height?: string
    undo?: boolean
    modelValue?: string
    required?: boolean
  }>(),
  {
    label: 'Signature',
    width: '100%',
    height: '150px',
    undo: false
  }
)

const emit = defineEmits(['update:modelValue'])

let signaturePad: SignaturePad
const signatureCanvas = ref<HTMLCanvasElement | null>(null)

// Remove last element from the signature
function undoAction() {
  const data = signaturePad.toData()
  if (data) {
    data.pop()
    signaturePad.fromData(data)
  }
}

// Resize the canva to always fit the cursor
const resizeCanvas = () => {
  const canvas = signatureCanvas.value
  if (canvas) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    const context = canvas.getContext('2d')
    if (context) {
      context.scale(ratio, ratio)
    }
    signaturePad.clear()
  }
}

onMounted(() => {
  if (signatureCanvas.value) {
    signaturePad = new SignaturePad(signatureCanvas.value)
    resizeCanvas()
  }

  signaturePad.addEventListener('endStroke', () => {
    emit('update:modelValue', signaturePad.toDataURL())
  })
})
</script>

<style scoped>
.label {
  margin-bottom: 15px;
  font-weight: 500;
}

canvas {
  width: 100%;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
}

.button-wrapper {
  display: flex;
  gap: 10px;
}
</style>
