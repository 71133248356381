<template>
  <div class="intervention-list-filters">
    <h4 class="mb-10">Filtres :</h4>
    <a class="reset-filters" href="#" @click.prevent="intervention_store.resetFilters">
      Réinitialiser les filtres
    </a>
    <InputButtonList
      id="skill_filter"
      class="mb-10"
      label="Type d'intervention"
      v-model="intervention_store.filters.skills"
      :items="
        intervention_store.getCategories.map((c) => {
          return { label: c, value: c }
        })
      "
    />
    <InputButtonList
      id="consultant_filter"
      class="mb-10"
      label="Intervenant"
      v-model="intervention_store.filters.consultant"
      :items="intervention_store.getConsultants"
    />
    <InputButtonList
      id="commercial_filter"
      class="mb-10"
      label="Référent commercial"
      v-model="intervention_store.filters.commercial"
      :items="appdata.getAdminsOptions"
    />
    <InputButtonList
      id="refered_filter"
      class="mb-10"
      label="Référent PF"
      v-model="intervention_store.filters.refered"
      :items="appdata.getAdminsOptions"
    />
    <InputButtonList
      id="status_filter"
      class="mb-10"
      label="Commanditaire"
      v-model="intervention_store.filters.backer"
      :items="intervention_store.getBackers"
    />
    <InputButtonList
      id="status_filter"
      class="mb-20"
      label="Bénéficiaire"
      v-model="intervention_store.filters.client"
      :items="intervention_store.getClients"
    />
    <InputCheckList
      id="client_status_filter"
      class="mb-20"
      label="Statut client"
      :items="getClientStatusArray()"
      v-model="intervention_store.filters.clientStatus"
    />
    <InputCheckList
      id="status_filter"
      class="mb-20"
      label="Statut intervenant"
      :items="getConsultantStatusArray()"
      v-model="intervention_store.filters.consultantStatus"
    />
    <InputCheckList
      id="status_filter"
      class="mb-20"
      label="Fiche mission"
      :items="getMissionStatusArray()"
      v-model="intervention_store.filters.missionStatus"
    />
    <InputBoolean
      id="document_filter"
      label="Pochette envoyée"
      v-model="intervention_store.filters.isDocumentFolderSent"
    />
  </div>
</template>

<script setup lang="ts">
import { useInterventionStore } from '@/stores/gae/store_intervention'
import InputButtonList from '../common/input/InputButtonList.vue'
import {
  getClientStatusArray,
  getMissionStatusArray,
  getConsultantStatusArray
} from '@/services/service_intervention'
import InputCheckList from '../common/input/InputCheckList.vue'
import InputBoolean from '../common/input/InputBoolean.vue'
import { useAppData } from '@/stores/gae/store_appdata'

const intervention_store = useInterventionStore()
const appdata = useAppData()
</script>

<style scoped>
.reset-filters {
  max-width: fit-content;
  display: block;
  margin-bottom: 18px;
  opacity: 0.75;
  text-decoration: underline;
  color: var(--vc-dark);
}

@media screen and (max-width: 1280px) {
  .intervention-list-filters {
    margin-bottom: 30px;
  }
}
</style>
