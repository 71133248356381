<template>
  <LayoutModal :can-close="!confirm_open" ref="modal" @on-closed="dynform_store.in_group = false">
    <LayoutModalContent class="modal-content">
      <CommonReturn @click="() => modal?.close(false, false)" text="Retour" />
      <div class="header">
        <div>
          <div>
            <h2>
              {{ isUnique ? 'Groupe unique' : `Groupe ${group_key + 1}` }}
            </h2>
            <CommonChip transparent size="large" color="dark">
              {{ dynform_store.getEntriesText(group.id!) }}
            </CommonChip>
          </div>
          <CommonIconLabel size="medium" icon="Clock">
            {{ getTimeString(group.start) }} - {{ getTimeString(group.end) }}
          </CommonIconLabel>
        </div>
        <CommonQRCode :link="getUrl()" />
      </div>
      <CommonButton
        @click="refresh"
        filled
        color="primary"
        icon="RefreshCcw"
        class="mb-20"
        :loading="dynform_store.isFetching"
      >
        Actualiser
      </CommonButton>
      <template v-if="dynform_store.dynform_entries.get(group.id!) ?? [].length > 0">
        <CompositeDynformTraineeCard
          v-for="entry of dynform_store.dynform_entries.get(group.id!) ?? []"
          :entry="entry"
          :key="entry.uuid!"
          class="mb-20"
          @delete-entry="refresh"
        />
      </template>
      <template v-else>
        <LabelInfo> Aucun participant pour le moment </LabelInfo>
      </template>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import CommonReturn from '@/components/common/CommonReturn.vue'
import CommonChip from '@/components/common/CommonChip.vue'
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import { ref } from 'vue'
import CommonQRCode from '@/components/common/CommonQRCode.vue'
import CompositeDynformTraineeCard from '../CompositeDynformTraineeCard.vue'
import { useDynformStore } from '@/stores/gae/store_dynform'
import CommonButton from '@/components/common/CommonButton.vue'
import type { InterventionGroup } from 'types/api/intervention'
import { getTimeString } from '@/services/service_date'
import LabelInfo from '../label/LabelInfo.vue'

const props = defineProps<{
  group_key: number
  group: InterventionGroup
  isUnique: boolean
}>()

const modal = ref<InstanceType<typeof LayoutModal>>()
const confirm_open = ref(false)
const dynform_store = useDynformStore()
function getUrl() {
  return `${window.location.origin}/trainee_form?intervention_id=${dynform_store.intervention_id}&category=${dynform_store.selected_category}&group=${props.group.id}`
}
function open() {
  if (modal.value) {
    modal.value.open()
    dynform_store.in_group = true
  }
}

function refresh() {
  dynform_store.fetchDynformEntryByIntervention(dynform_store.intervention_id!)
}

defineExpose({ open })
</script>

<style scoped>
.modal-content {
  margin-top: 46px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div > div {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.button {
  margin-bottom: 20px;
}
</style>
