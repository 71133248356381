<template>
  <component :is="layoutCard ? LayoutCard : 'div'">
    <div class="panel">
      <div
        :class="['header', layoutCard ? 'header-card' : '']"
        @click.prevent="toggle"
        v-if="!disable"
      >
        <h3>{{ title }}</h3>
        <slot name="header" />
        <CommonIcon
          :stroke-width="1.5"
          :size="40"
          :name="show ? 'ChevronUp' : 'ChevronDown'"
          color="#263765"
        />
      </div>
      <div class="content" v-if="show || disable">
        <slot></slot>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CommonIcon from './CommonIcon.vue'
import LayoutCard from '../layout/LayoutCard.vue'

defineProps<{
  title: string
  layoutCard?: boolean
  disable?: boolean
}>()

const show = ref(false)

function toggle() {
  emits('beforeOpen')
  show.value = !show.value
}

function setValue(value: boolean) {
  show.value = value
}

const emits = defineEmits<{
  (e: 'beforeOpen'): void
}>()

defineExpose({ setValue })
</script>

<style scoped lang="scss">
.header {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #f0f1f5;
  cursor: pointer;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
}

.header {
  padding: 30px 0px;
  margin-bottom: 30px;
}

.header > h3 {
  flex: 1;
}

.header-card {
  padding: 0px !important;
  margin-bottom: 0px !important;
  border-bottom: 0px !important;
}

@media screen and (max-width: 640px) {
  .header > h2 {
    font-size: 25px;
  }
}
</style>
