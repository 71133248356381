<template>
  <ModalGroup
    ref="modal"
    :group="group"
    :group_key="group_key"
    :isUnique="props.group_count === 1"
  />
  <LayoutCard @click="() => modal?.open()" class="title-card">
    <div class="composite-group-card">
      <div class="left-group">
        <h5 class="title">{{ group_count === 1 ? 'Groupe unique' : `Groupe ${group_key + 1}` }}</h5>
        <CommonIconLabel size="medium" icon="Clock">
          {{ getTimeString(group.start) }} - {{ getTimeString(group.end) }}
        </CommonIconLabel>
      </div>
      <div class="right-group">
        <CommonChip
          transparent
          size="large"
          :color="getTraineesCompletion() ? 'success' : 'danger'"
        >
          {{ store.getEntriesText(group.id!) }} {{ intern_count !== 0 ? ` / ${intern_count}` : '' }}
        </CommonChip>
        <CommonIconButton
          v-if="deletable"
          icon="Trash2"
          color="dark"
          @click.stop.prevent="deleteGroup()"
        />
        <CommonIconButton icon="Edit" color="dark" @click.stop.prevent="edit_group = !edit_group" />
        <CommonIconButton color="primary" icon="ArrowRight" class="icon" @click="emits('open')" />
      </div>
    </div>
    <div v-if="edit_group" class="edit-group mt-20">
      <LayoutTwoColumn style="margin-bottom: 12px">
        <InputTime id="group-start" label="Debut" v-model="start_time" @click.stop />
        <InputTime id="group-start" label="Fin" v-model="end_time" @click.stop />
      </LayoutTwoColumn>
      <InputCommon
        style="margin-bottom: 12px"
        id="group-count"
        label="Nombre de participants"
        v-model="participants"
        @click.stop
      />
      <CommonLoader v-if="edit_loading" />
      <CommonIconButton
        :disabled="participants === '' || edit_loading"
        @click.stop.prevent="editGroup()"
        label="Modifier le groupe"
        icon="Check"
        squared
      />
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '../layout/LayoutCard.vue'
import CommonIconLabel from '../common/CommonIconLabel.vue'
import CommonIconButton from '../common/CommonIconButton.vue'
import CommonChip from '../common/CommonChip.vue'
import ModalGroup from './modal/ModalGroup.vue'
import { ref, watch } from 'vue'
import type { DynformEntry } from 'types/api/dynform'
import type { InterventionGroup } from 'types/api/intervention'
import { getTimeString } from '@/services/service_date'
import { useDynformStore } from '@/stores/gae/store_dynform'
import LayoutTwoColumn from '../layout/LayoutTwoColumn.vue'
import InputCommon from '../common/input/InputCommon.vue'
import { getTimeModelFromISO } from '@/services/service_date'
import InputTime from '../common/input/InputTime.vue'
import type { TimeModel } from '@vuepic/vue-datepicker'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CommonLoader from '../common/CommonLoader.vue'

const props = defineProps<{
  group_key: number
  group: InterventionGroup
  group_count: number
  entries: DynformEntry[]
  intern_count: number
  deletable?: boolean
}>()

const store = useDynformStore()
const intervention_store = useInterventionStore()
const modal = ref<InstanceType<typeof ModalGroup>>()

const emits = defineEmits(['open', 'delete-group', 'edit-group'])
const start_time = ref<TimeModel>(getTimeModelFromISO(props.group.start))
const end_time = ref<TimeModel>(getTimeModelFromISO(props.group.end))
const participants = ref<string>(props.intern_count.toString())
const edit_group = ref<boolean>(false)
const edit_loading = ref(false)

async function deleteGroup() {
  intervention_store.deleteGroup(props.group.id!).then(() => {
    emits('delete-group', props.group.id)
  })
}

async function editGroup() {
  edit_loading.value = true

  const start = props.group.start
    ? `${start_time.value?.hours}:${start_time.value?.minutes}`
    : props.group.start

  const end = end_time.value
    ? `${end_time.value?.hours}:${end_time.value?.minutes}`
    : props.group.end

  const count_inter = participants.value ?? props.group.intern_count

  intervention_store.editGroup(props.group.id!, start, end, count_inter).then((response) => {
    if (response) {
      emits('edit-group', response)
    }
    edit_group.value = false
    start_time.value = getTimeModelFromISO(response!.start)
    end_time.value = getTimeModelFromISO(response!.end)
    participants.value = '0'
    edit_loading.value = false
  })
}

function getTraineesCompletion() {
  for (const [_, entries] of store.dynform_entries) {
    for (const entry of entries) {
      if (entry.group_id !== props.group.id) continue
      if (entry.isConsultant) continue
      if (!entry.state) continue
      for (const state_step of entry.state.steps) {
        if (state_step.incomplete_questions > 0) {
          return false
        }
      }
    }
  }
  return true
}
</script>

<style scoped>
.title-card {
  cursor: pointer;
}

.composite-group-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-group {
  display: flex;
  gap: 20px;
}

.left-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
