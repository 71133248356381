<template>
  <div class="input-select-wrapper">
    <div ref="select" class="input-select" :class="{ open: open }">
      <label :for="id">{{ label ?? 'Compétences' }} :</label>
      <div class="input" @click="open = !open">
        <input
          :id="id"
          type="text"
          :placeholder="
            selected_values?.length == 0 ? 'Sélectionnez une ou des interventions...' : ''
          "
        />
        <div class="selected-values">
          <CommonChip
            v-for="value of selected_values"
            :key="value.id"
            @click.stop="remove(value)"
            style="cursor: pointer"
            filled
            color="dark"
            >{{ fixDate(value.date) }}<X :size="10" style="margin-left: 5px" />
          </CommonChip>
        </div>
      </div>
      <ChevronUp class="input-select-chevron" @click="open = !open" />
      <div class="input-select-options">
        <div class="input-skills-search">
          <input placeholder="Rechercher.." v-model="search_input" />
        </div>
        <div class="options">
          <div v-for="(option, key) in getOptions()" :key="key" class="input-select-option">
            <div v-if="!added(option)" @click="add(option)">
              <p>{{ option.title }} - {{ fixDate(option.date) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ChevronUp } from 'lucide-vue-next'
import { computed, onMounted, ref, watch } from 'vue'
import type { Intervention, InterventionInputOptions } from 'types/api/intervention'
import { X } from 'lucide-vue-next'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CommonChip from '../CommonChip.vue'

const emits = defineEmits(['update:modelValue', 'onChange'])
const intervention_store = useInterventionStore()

const props = defineProps<{
  id: string
  modelValue?: Intervention[]
  label?: string
  placeholder?: string
}>()

const open = ref(false)
const select = ref()
const search_input = ref('')

function getOptions() {
  if (search_input.value.length > 1) {
    return intervention_store.fetchedSearch.filter((e: Intervention) =>
      e.title.toLowerCase().includes(search_input.value.toLowerCase())
    )
  } else {
    return intervention_store.fetchedSearch
  }
}

function fixDate(date: any) {
  return new Date(date.date).toLocaleDateString('fr', {
    hour: undefined,
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })
}

const selected_values = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: Intervention[]): void => {
    emits('update:modelValue', v)
  }
})

function remove(intervention: Intervention) {
  if (selected_values.value) {
    const i = selected_values.value.findIndex((e) => e.id == intervention.id)
    selected_values.value.splice(i, 1)
  }
  emits('onChange')
}

function add(intervention: Intervention) {
  selected_values.value?.push(intervention)
  open.value = false

  emits('onChange')
}

function added(intervention: Intervention) {
  if (selected_values.value?.find((e) => e.id == intervention.id)) {
    return true
  }
  return false
}

onClickOutside(select, () => (open.value = false))
</script>

<style scoped lang="scss">
.selected-values {
  top: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  padding: 20px;
}

.input-select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-select-options {
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--vc-white);
  border: 0px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 0px 0px 5px 5px;
  z-index: 100;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 0px;
  overflow: scroll;
}

.input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 50px;
  right: 20px;
  cursor: pointer;
}

.input-select > label {
  margin-bottom: 15px;
  pointer-events: none;
}

.input-select > div {
  cursor: pointer;
}

.input-select > div > input {
  pointer-events: none;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
  position: absolute;
  height: 100%;
}

.input-select-options::-webkit-scrollbar {
  display: none;
}

.input-select.open .input-select-options {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 300px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-top: unset;
}

.input-select.open .input-select-options .input-select-option > div {
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.input-select-option > div {
  padding: 20px 0px;
}

.input-select-option {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  padding: 0px 20px;
}

.input-select-option:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(var(--vcrgb-dark), 0.05);
}

.error > div > input {
  margin-bottom: 5px;
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

.input-select.open > div > input {
  border-bottom: unset;
  border-radius: 5px 5px 0px 0px;
}

.input-select.open > .input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-180deg);
}

.input {
  position: relative;
  min-height: 60px;
}

.input-skills-search {
  padding: 0 20px;
}

.input-skills-search > input {
  border: 0;
  top: 0;
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
  width: 100%;
  padding: 10px 0;
}
</style>
