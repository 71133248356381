<template>
  <CommonHeader :title="getConsultant() ? 'Planning de l\'intervenant' : 'Mes disponibilités'" />
  <LayoutContainer :max-width="1040">
    <RouterLink v-if="getConsultant()" :to="`/extranet/users?u=${getConsultant()}`">
      <CommonReturn class="mb-20" text="Retour à l'intervenant" />
    </RouterLink>
    <CompositeAvailabilityCalendar :consultant="getConsultant()" />
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonReturn from '@/components/common/CommonReturn.vue'
import CompositeAvailabilityCalendar from '@/components/composite/CompositeAvailabilityCalendar.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
function getConsultant(): number | undefined {
  if (route.params && route.params.id) {
    return parseInt(route.params.id as string)
  }
  return undefined
}
</script>
