<template>
  <div>
    <div class="composite-form-container" v-if="!fetching">
      <div class="header" v-if="showHeader && dynform">
        <div>
          <h2>Groupe {{ dynform.group_key + 1 }}</h2>
        </div>
      </div>
      <form
        class="composite-form"
        v-for="([step, questions], key) in getFormQuestions()"
        :key="step"
      >
        <CommonAccordion
          v-if="step < 7 && filteredQuestions(questions).length > 0"
          :disable="filteredQuestions(questions).length === 1"
          :title="STEPS[step]"
          :state="store.getDynformCompletionState(questions)"
          :stateLabels="{ true: 'Saisi', false: 'A saisir' }"
          ref="accordion"
          @before-open="closeOtherAccordions(key)"
        >
          <template #header>
            <CommonChip
              filled
              size="small"
              transparent
              :color="store.getDynformCompletionState(questions) ? 'success' : 'danger'"
            >
              {{ store.getDynformCompletionState(questions) ? 'Saisi' : 'À saisir' }}
            </CommonChip>
          </template>
          <template #default>
            <div class="question-container">
              <template v-for="(question, i) in filteredQuestions(questions)">
                <InputBoolean
                  :key="`boolean_${question.id}`"
                  v-if="question.type === 1"
                  :label="question.label"
                  :id="`boolean_${i}`"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <InputCommon
                  :key="`common_${question.id}`"
                  v-else-if="question.type === 0 || question.type === 2"
                  :label="question.label"
                  :id="`common_${i}`"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  :capitalize="question.flags.includes('FLAG_CAPITALIZE')"
                  :uppercase="question.flags.includes('FLAG_UPPERCASE')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <InputArea
                  :key="`area_${question.id}`"
                  v-else-if="question.type === 3"
                  :label="question.label"
                  :id="`area_${i}`"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <InputDate
                  :key="`date_${question.id}`"
                  v-else-if="question.type === 4"
                  :id="`date_${i}`"
                  :label="question.label"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <InputTime
                  :key="`time_${question.id}`"
                  v-else-if="question.type === 5"
                  :id="`time_${i}`"
                  :label="question.label"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <InputCheckboxSingle
                  v-else-if="question.type === 9"
                  :key="`checkbox_${question.id}`"
                  :id="`checkbox_${i}`"
                  :label="question.label"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  v-model="question.answer"
                  @update:model-value="submitted = []"
                />
                <template v-else-if="question.type === 6">
                  <CompositeSignaturePad
                    :key="`signature_${question.id}`"
                    v-if="!question.answered || editSign"
                    :label="question.label"
                    :id="`signature_${i}`"
                    :required="question.flags.includes('FLAG_REQUIRED')"
                    v-model="question.answer"
                    @update:model-value="submitted = []"
                  />
                  <div v-else :key="`edit_sign_${question.id}`">
                    <h5 class="mb-20">{{ question.label }} :</h5>
                    <p class="mb-20">Votre signature à déja été enregistrée.</p>
                    <div :key="`edit_button_${question.id}`">
                      <CommonButton
                        color="primary"
                        :icon="editSign ? 'Ban' : 'Edit'"
                        @click.prevent="handleSignatureEdit()"
                      >
                        Modifier la signature
                      </CommonButton>
                    </div>
                  </div>
                </template>
                <InputRadio
                  v-else-if="question.type === 7 && question.choices"
                  :key="`radio_${question.id}`"
                  :id="`radio_${i}`"
                  v-model="question.answer"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  :label="question.label"
                  :options="
                    question.choices.map((c, index) => ({ label: c, value: String(index + 1) }))
                  "
                  @update:model-value="submitted = []"
                />
                <InputRating
                  v-else-if="question.type === 8"
                  v-model="question.answer"
                  :key="`cursor_${question.id}`"
                  :label="question.label"
                  :id="`cursor_${i}`"
                  :numbers="5"
                  :required="question.flags.includes('FLAG_REQUIRED')"
                  @update:model-value="submitted = []"
                />
                <template v-else-if="question.type === 10">
                  <div class="input-rules" :key="`rules_${question.id}`">
                    <div>
                      <CommonIcon name="Link" :stroke-width="1.5" :size="16" />
                      <a href="/api/pdf/reglement.pdf" target="_blank"> Réglement intérieur </a>
                    </div>
                    <InputCheckboxSingle
                      :key="`checkbox_${question.id}`"
                      :id="`checkbox_${i}`"
                      :label="question.label"
                      :required="question.flags.includes('FLAG_REQUIRED')"
                      v-model="question.answer"
                      @update:model-value="submitted = []"
                    />
                  </div>
                </template>
              </template>
            </div>
            <CommonButton
              v-if="!submitted.includes(step)"
              @click.prevent="submit(step)"
              style="width: 100%"
              filled
              color="success"
              icon="Check"
              :loading="store.isFetching"
              :disabled="
                filteredQuestions(questions).find(
                  (e) =>
                    e.flags.includes('FLAG_NEED') && (e.answer === false || e.answer === 'false')
                ) !== undefined
              "
            >
              Je valide mes réponses
            </CommonButton>
          </template>
        </CommonAccordion>
      </form>
    </div>
    <div v-else class="composite-form-loader">
      <p>Chargement des questions...</p>
      <CommonLoader />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDynformStore } from '@/stores/gae/store_dynform'
import { onMounted, ref } from 'vue'
import InputCommon from '../common/input/InputCommon.vue'
import InputBoolean from '../common/input/InputBoolean.vue'
import CompositeSignaturePad from './CompositeSignaturePad.vue'
import InputArea from '../common/input/InputArea.vue'
import { getContract } from '@/services/service_dynform'
import { useCookies } from '@/composable/cookies'
import CommonAccordion from '../common/CommonAccordion.vue'
import InputDate from '../common/input/InputDate.vue'
import InputTime from '../common/input/InputTime.vue'
import InputRadio from '../common/input/InputRadio.vue'
import CommonButton from '../common/CommonButton.vue'
import { STEPS } from '@/stores/gae/store_dynform'
import type { Dynform } from 'types/api/dynform'
import type { orderedQuestion } from '@/stores/gae/store_dynform'
import CommonIcon from '../common/CommonIcon.vue'
import CommonLoader from '../common/CommonLoader.vue'
import InputRating from '../common/input/InputRating.vue'
import InputCheckbox from '../common/input/InputCheckbox.vue'
import InputCheckboxSingle from '../common/input/InputCheckboxSingle.vue'
import CommonChip from '../common/CommonChip.vue'

const props = withDefaults(
  defineProps<{
    dynform?: Dynform
    intervention_id: number
    category_id: number
    showHeader?: boolean
    group: number
    isConsultantForm: boolean
  }>(),
  {
    showHeader: false,
    isConsultantForm: false
  }
)

const store = useDynformStore()
const cookie = useCookies()
const editSign = ref(false)
const submitted = ref<number[]>([])
const fetching = ref(true)
const accordion = ref<InstanceType<typeof CommonAccordion>[]>([])
const emits = defineEmits(['loaded', 'submit'])

async function submit(step: number) {
  const answers = store.getDynformQuestions

  const contract = getContract(answers, props.intervention_id, props.group, props.category_id, step)
  if (props.isConsultantForm) {
    contract.isConsultant = true
  }

  store.postDynformEntry(contract).then(() => {
    submitted.value.push(step)
    editSign.value = false
    if (accordion.value) {
      for (const acc of accordion.value) {
        acc.setValue(false)
      }
    }
    emits('submit')
  })
}

function getFormQuestions(): Map<number, orderedQuestion[]> {
  store.form_dynform_questions.forEach((questions) => {
    questions.forEach((question) => {
      if (question.answer === '' && question.type === 1) {
        question.answer = null
      }
    })
  })
  return store.form_dynform_questions
}

function filteredQuestions(questions: orderedQuestion[]) {
  if (props.isConsultantForm) {
    return questions.filter((e) => e.flags.includes('FLAG_CONSULTANT'))
  } else {
    return questions.filter((e) => !e.flags.includes('FLAG_CONSULTANT'))
  }
}

function handleSignatureEdit() {
  editSign.value = !editSign.value
}

function closeOtherAccordions(index: number) {
  if (accordion.value && accordion.value.length > 1) {
    for (const key in accordion.value) {
      if (parseInt(key) != index) {
        accordion.value[key].setValue(false)
      }
    }
  }
}

onMounted(async () => {
  store.fetchDynformQuestions(props.intervention_id, props.category_id).then(async () => {
    if (props.isConsultantForm) {
      if (store.dynform && store.dynform.consultant_uuid) {
        cookie.setCookieValue('user_uuid', store.dynform.consultant_uuid)
      } else {
        cookie.deleteCookieValue('user_uuid')
      }
    }

    const user = cookie.getCookieValue('user_uuid')
    if (user.value && store.form_dynform_questions.size !== 0) {
      await store.fetchDynformEntry(user.value)
    }

    fetching.value = false
    emits('loaded')
  })
})
</script>

<style scoped>
.composite-form-container {
  margin: auto;
  width: 100%;
}
.composite-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: slidein 2s cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.header > div {
  background-color: #f0f1f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 10px;
  padding: 10px 15px;
}
.question-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.input-rules {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-rules > div {
  color: var(--vc-dark);
}

.input-boolean > div > a {
  text-decoration: underline;
}

.composite-form-loader {
  width: 100%;
  color: var(--vc-dark);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 640px) {
  .header > div > h2 {
    font-size: 25px;
  }
}
</style>
