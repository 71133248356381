import type { orderedQuestion } from "@/stores/gae/store_dynform"
import type { DynformEntryPOST } from "types/api/dynform"
import type { DynformAnswerDTO } from "types/api/dynform"
import { useCookies } from "@/composable/cookies"

const cookies = useCookies();

export function getContract(form: Map<number, orderedQuestion[]>, intervention_id: number, group: number, category_id: number, step: number, uuid?: string): DynformEntryPOST {
    const contract: DynformEntryPOST = {
        step,
        uuid: uuid ?? cookies.getCookieValue('user_uuid').value ?? undefined,
        group_id: group,
        category_id: category_id,
        intervention_id: intervention_id,
        answers: [] as DynformAnswerDTO[]
    }
    for (const [_, questions] of form)
        for (const question of questions) {
            if (question.step !== step) continue
            if (question.answer === null) continue
            if (question.answer === "") {
                continue
            }
            //if signature already sent
            if (question.type === 6 && question.answer.startsWith('signature-')) {
                continue
            }
            contract.answers.push({ question_id: question.id, value: question.answer.toString(), media: null })
            contract.step = question.step
        }
    return contract
}