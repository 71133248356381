<template>
  <LayoutSidebar>
    <template #left>
      <CommonSidebarItem
        v-if="!security.isAdmin && !security.isAdminReadonly"
        to="/extranet"
        icon="Home"
        title="Mes missions"
      />
      <CommonSidebarItem
        v-if="security.isAdmin"
        to="/extranet/dashboard"
        icon="Home"
        title="Tableau de bord"
      />
      <CommonSidebarItem
        v-if="security.isAdmin || security.isAdminReadonly"
        to="/extranet/calendar"
        icon="CalendarDays"
        title="Calendrier"
      />
      <CommonSidebarItem
        v-else-if="!security.isAdminReadonly"
        to="/extranet/availabilities"
        icon="CalendarDays"
        title="Mes disponibilités"
      />
      <CommonSidebarItem
        v-if="security.isAdmin || security.isAdminReadonly"
        to="/extranet/users"
        icon="Users"
        title="Liste des intervenants"
      />
      <CommonSidebarItem
        v-if="!security.isAdmin && !security.isAdminReadonly"
        to="/extranet/missing_material"
        icon="FileQuestion"
        title="Matériel manquant"
      />
      <CommonSidebarItem
        v-if="!security.isAdmin && !security.isAdminReadonly"
        to="/extranet/billing"
        icon="Wallet"
        title="Ma facturation"
      />
    </template>
    <template #content>
      <RouterView v-if="security.user" />
    </template>
  </LayoutSidebar>
</template>

<script setup lang="ts">
import CommonSidebarItem from '@/components/common/CommonSidebarItem.vue'
import LayoutSidebar from '@/components/layout/LayoutSidebar.vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import { useRouter } from 'vue-router'

const security = useSecurityStore()
const router = useRouter()

if (security.isAdmin || security.isAdminReadonly) {
  router.push('/extranet/calendar')
}
</script>
