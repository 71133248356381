<template>
  <div class="layout-tabs">
    <div class="layout-tabs-tabs" :class="{ disabled }">
      <a
        href="#"
        v-for="(tab, i) in slot_names.map((e) => e.replace(/_/g, ' '))"
        :key="i"
        :class="{ selected: selected_tab == i }"
        @click="() => (selected_tab = i)"
      >
        <div>
          <CommonIcon v-if="icons" :name="icons[i]" :size="20" />
          {{ tab }}
        </div>
      </a>
    </div>
    <template v-for="(tab, i) in slot_names" :key="i">
      <div v-if="selected_tab == i" class="layout-tabs-wrapper">
        <slot :name="tab" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'
import CommonIcon, { type Icons } from '../common/CommonIcon.vue'

const slots = useSlots()
const slot_names = Object.keys(slots) as 'default'[]
const selected_tab = ref(0)

const props = defineProps<{
  icons?: Icons[]
  disabled?: boolean
}>()

function setTab(index: number) {
  selected_tab.value = index
}

defineExpose({ setTab })
</script>

<style scoped>
.layout-tabs-tabs {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid rgba(var(--vcrgb-dark), 0.2);
  display: flex;
  margin-bottom: 30px;
}

.layout-tabs-tabs > a {
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
  display: block;
  text-transform: capitalize;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--vc-dark);
  padding: 15px 20px;
  border-bottom: 0;
  width: fit-content;
}

.layout-tabs-tabs > a > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.layout-tabs-wrapper {
  padding: 0;
}

.layout-tabs-tabs > a.selected {
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
  color: var(--vc-white);
  border-radius: 5px 5px 0 0;
}

.disabled a:not(.selected) {
  color: rgba(var(--vcrgb-dark), 0.5) !important;
  pointer-events: none;
}
</style>
