<template>
  <LayoutModal ref="modal">
    <CommonHeader class="mb-30" title="Ajouter un intervenant">
      <template #action>
        <CommonReturn @click="modal?.close()" />
      </template>
    </CommonHeader>
    <LayoutModalContent>
      <FormConsultant ref="form" />
      <CommonButton
        :loading="user_store.fetching"
        @click="submit"
        class="submit"
        icon="Plus"
        filled
      >
        Ajouter l'intervenant
      </CommonButton>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonReturn from '@/components/common/CommonReturn.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import { ref } from 'vue'
import FormConsultant from '../forms/FormConsultant.vue'
import type { APIConsultantPOSTRequest } from 'types/api/consultant'
import { useUserStore } from '@/stores/gae/store_user'
import { contract } from '@/services/service_consultant'
import { useToast } from 'vue-toast-notification'

defineExpose({ create })
const user_store = useUserStore()
const modal = ref<InstanceType<typeof LayoutModal>>()
const form = ref<InstanceType<typeof FormConsultant>>()

function create() {
  if (modal.value) {
    modal.value.open()
  }
}

function getContract(): APIConsultantPOSTRequest | undefined {
  if (form.value) {
    const data = form.value.getData()
    return contract(data)
  }
}

async function submit() {
  const validation = await form.value?.getValidation()
  if (!validation) {
    const toast = useToast()
    toast.error('Certains champs sont invalides.')
    return
  }

  const contract = getContract()
  if (contract) {
    try {
      await user_store.post(contract)
    } catch (e) {
      return
    }

    if (modal.value) {
      modal.value.close()
    }
  }
}
</script>

<style scoped>
.submit {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 150px;
}
</style>
