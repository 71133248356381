<template>
  <CommonParagraph :title="title">
    <template
      v-if="
        (intervention.missionStatus === 'documents_uploaded' ||
          intervention.missionStatus === 'documents_ok') &&
        security.isAdmin
      "
      #right
    >
      <div class="download-all" href="">
        <a :href="`/api/intervention_done_documents_archive/${intervention.id}`">
          Télécharger les documents
        </a>
        <CommonIcon name="Download" :size="16" />
      </div>
    </template>
    <template #default v-if="security.isAdmin">
      <p v-if="intervention.documentCategories.length == 0">Aucun document n'est requis.</p>
      <div class="file-inputs">
        <CompositeFileCard
          v-for="(category, i) in intervention.documentCategories"
          v-model="categories[i]"
          :intervention="intervention"
          :category="category.id"
          :link="category.acceptsLink"
          :title="getCategoryTitle(category.name)"
          :readonly="readonly_ref"
          :key="category.id"
        />
      </div>
    </template>
  </CommonParagraph>
</template>

<script setup lang="ts">
import CommonParagraph from '@/components/common/CommonParagraph.vue'
import CompositeFileCard from '../CompositeFileCard.vue'
import CommonButton from '@/components/common/CommonButton.vue'
import { onBeforeMount, ref } from 'vue'
import type { Intervention, InterventionDoneDocument } from 'types/api/intervention'
import { useFile } from '@/stores/gae/store_file'
import { useSecurityStore } from '@/stores/gae/store_security'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CommonIcon from '@/components/common/CommonIcon.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'

const props = defineProps<{
  title: string
  intervention: Intervention
  readonly?: boolean
}>()

const security = useSecurityStore()
const file_store = useFile()
const intervention_store = useInterventionStore()
const categories = ref<InterventionDoneDocument[][]>([])
const readonly_ref = ref(props.readonly ?? false)
const customer_email = ref<string>('')

function complete() {
  const doc_cat = props.intervention.documentCategories

  for (const [i, category] of categories.value.entries()) {
    if (category.length > 0) continue
    if (doc_cat[i].name === 'Autres documents') continue
    return false
  }
  return true
}

function getCategoryTitle(title: string) {
  if (!props.intervention.skills.some((skill) => skill.category.toLowerCase() === 'formation')) {
    return title
  }

  if (title === 'Autres documents') {
    title += ' ( Questionnaires des Acquis, Questionnaires de Satisfaction, ...)'
  }
  if (title === 'Rapport de formation') {
    title += ' : copier le lien utilisé une fois le rapport fait'
  }
  return title
}

function submit() {
  if (confirm("Confirmer l'envoi des documents ?")) {
    file_store.sendInterventionDocuments(props.intervention)
  }
}

onBeforeMount(() => {
  for (let i = 0; i < props.intervention.documentCategories.length; i++) {
    categories.value[i] = props.intervention.documentCategories[i].documents
  }
})
</script>

<style scoped>
.file-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.send {
  width: 100%;
  margin-bottom: 30px;
}

.download-all {
  color: var(--vc-primary);
  display: flex;
  gap: 10px;
  text-decoration: underline;
}
</style>
