<template>
  <CommonAccordion :title="getIdentifier()" layoutCard>
    <template #header>
      <div class="steps-chips">
        <CommonChip
          v-for="step in entry.state?.steps"
          :key="step.step_id"
          filled
          transparent
          size="xsmall"
          :color="step.completion == 100 ? 'success' : 'danger'"
        >
          {{ step.step_label }}
        </CommonChip>
      </div>
    </template>
    <template #default>
      <div class="steps-wrapper">
        <div v-for="step in entry.state?.steps" :key="step.step_id">
          <div>
            <CommonIcon
              :name="getName(step.completion)"
              :color="getColor(step.completion)"
              :size="25"
            />
            <p>{{ step.step_label }}</p>
          </div>
          <CommonProgressBar
            v-if="step.completion > 0 && step.completion < 100"
            :percentage="step.completion"
            :key="step.step_id"
          />
        </div>
      </div>
      <div
        v-if="
          !isChildIntervention() &&
          [1].includes(category()) &&
          !is_complete &&
          !getIntervention()?.ignoredSteps.includes(7)
        "
        class="composite-dynform-trainee-card-questions"
      >
        <h5 style="margin-bottom: 10px">Attestation</h5>
        <InputRadio
          v-for="question in questions"
          :key="`${entry.uuid}-${question.id}`"
          :id="`${entry.uuid}-${question.id}`"
          :label="question.label"
          v-model="question.answer"
          style="margin-bottom: 15px"
          :options="question.choices.map((e, k) => ({ label: e, value: k.toString() }))"
        />
        <CommonButton
          filled
          @click="generateAttestation"
          color="success"
          icon="Check"
          :disabled="questions.find((e) => e.answer === '') ? true : false"
        >
          Compléter l'attestation
        </CommonButton>
      </div>
      <CommonButton
        icon="Download"
        color="success"
        style="margin-bottom: 12px"
        v-else-if="is_complete && security.isAdmin"
      >
        <a :href="getPDFUrl(6)"> Télécharger l'attestation </a>
      </CommonButton>
      <CommonButton
        style="margin-bottom: 12px"
        @click="copyURL()"
        class="copy-button"
        icon="Copy"
        size="small"
        color="primary"
      >
        Copier le lien du formulaire
      </CommonButton>
      <template v-if="security.isAdmin && [1, 2, 3].includes(category())">
        <h4>Formulaires :</h4>
        <div style="display: flex; max-width: 80%; flex-wrap: wrap; gap: 10px; margin-top: 15px">
          <CommonButton
            v-if="[1, 2, 3].includes(category()) && !getIntervention()?.ignoredSteps.includes(2)"
            color="dark"
            size="small"
            icon="Download"
          >
            <a :href="getPDFUrl(0)">Questionaire de satisfaction</a>
          </CommonButton>
          <CommonButton
            v-if="[1].includes(category()) && !getIntervention()?.ignoredSteps.includes(3)"
            color="dark"
            size="small"
            icon="Download"
          >
            <a :href="getPDFUrl(1)">Questionaire des Acquis</a>
          </CommonButton>
        </div>
      </template>
      <CommonIconButton
        class="delete-button"
        icon="Trash2"
        color="dark"
        @click.prevent="deleteEntry(entry)"
      />
    </template>
  </CommonAccordion>
</template>

<script setup lang="ts">
import type { DynformEntry, DynformEntryPOST } from 'types/api/dynform'
import CommonAccordion from '../common/CommonAccordion.vue'
import CommonIcon from '../common/CommonIcon.vue'
import CommonProgressBar from '../common/CommonProgressBar.vue'
import { useDynformStore, type orderedQuestion } from '@/stores/gae/store_dynform'
import type { DynformStepData } from '@/stores/gae/store_dynform'
import CommonIconButton from '../common/CommonIconButton.vue'
import CommonButton from '../common/CommonButton.vue'
import { useToast } from 'vue-toast-notification'
import InputRadio from '../common/input/InputRadio.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import { onMounted, ref } from 'vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import { useRequestStore } from '@/stores/gae/store_requests'
import type { Intervention } from 'types/api/intervention'
import CommonChip from '../common/CommonChip.vue'
import { useCookies } from '@/composable/cookies'

const intervention_store = useInterventionStore()
const security = useSecurityStore()
const dynform_store = useDynformStore()
const props = defineProps<{ entry: DynformEntry }>()
const emits = defineEmits(['delete-entry'])
const toast = useToast()
const is_complete = ref(false)
const questions = ref<orderedQuestion[]>(
  JSON.parse(JSON.stringify(dynform_store.getConsultantTraineeFormQuestions))
)
const all_steps_completed = ref(true)

function isChildIntervention() {
  const intervention = intervention_store.getInterventionByID(props.entry.intervention_id)
  if (intervention === undefined) {
    const request_store = useRequestStore()
    const request = request_store.getRequestByInterventionID(props.entry.intervention_id)
    if (request && request.intervention.parentIntervention) {
      return true
    }
  }

  if (intervention && intervention.parentIntervention) {
    return true
  }

  return false
}

function copyURL() {
  const url = `${window.location.origin}/trainee_form?intervention_id=${props.entry.intervention_id}&category=${dynform_store.selected_category}&group=${props.entry.group_id}&entry=${props.entry.uuid}`
  try {
    navigator.clipboard.writeText(url).then(() => {
      toast.success('Clien copié')
    })
  } catch (e) {
    toast.error('Impossible de copier le lien')
  }
}

function getIdentifier() {
  const identifiers = dynform_store.getAnswersWithFlag(props.entry, 'FLAG_IDENTIFIER')
  if (identifiers.length === 0) return 'Inconnu'
  return identifiers.map((e) => e.value).join(' ')
}

function getName(completion: number) {
  if (completion === 0) {
    return 'X'
  } else if (completion === 100) {
    return 'Check'
  } else {
    return 'PencilLine'
  }
}

function getColor(completion: number) {
  if (completion === 0) {
    return '#e42b38'
  } else if (completion === 100) {
    return '#54b433'
  } else {
    return '#e28800'
  }
}

function getState(steps: DynformStepData[]) {
  for (const step of steps) {
    if (step.completion != 100) {
      all_steps_completed.value = false
      return false
    }
  }
  return true
}

function deleteEntry(entry: DynformEntry) {
  if (confirm('Confirmer la suppression du participant ?')) {
    dynform_store.deleteDynformEntry(entry.uuid!).finally(() => {
      emits('delete-entry')
    })
  }
}

function generateAttestation() {
  if (confirm("Confirmer la génération de l'attestation ?")) {
    if (props.entry.uuid) {
      const contract: DynformEntryPOST = {
        uuid: props.entry.uuid,
        answers: questions.value.map((e) => ({ question_id: e.id, media: null, value: e.answer })),
        category_id: category(),
        group_id: props.entry.group_id,
        intervention_id: props.entry.intervention_id,
        step: 7,
        isConsultant: false
      }
      dynform_store.postDynformEntry(contract).then(() => {
        is_complete.value = true
        props.entry.answers = contract.answers.concat(props.entry.answers)
        const cookies = useCookies()
        cookies.deleteCookieValue('user_uuid')
        dynform_store.fetchDynformEntryByIntervention(dynform_store.intervention_id!)
      })
    }
  }
}

function getPDFUrl(type: number) {
  return `${import.meta.env.VITE_GAE_API_URL}/dynform_entry/${props.entry.uuid}/documents/${type}`
}

function getIntervention(): Intervention | undefined {
  if (security.isAdmin) {
    return intervention_store.getInterventionByID(props.entry.intervention_id)
  } else {
    const request_store = useRequestStore()
    return request_store.getRequests.find((e) => e.intervention.id === props.entry.intervention_id)
      ?.intervention
  }
}

function category() {
  const intervention = getIntervention()
  return intervention?.serviceCategories[0].id ?? 0
}

onMounted(() => {
  const form_questions = dynform_store.dynform_questions
    .map((e) => ({
      ...e,
      answer: '',
      answered: false
    }))
    .filter((e) => e.step === 7)

  for (let question of form_questions) {
    const answer = props.entry.answers.find((e) => e.question_id === question.id)
    if (answer) {
      question.answer = answer.value
      question.answered = true
    }
  }

  const incomplete = form_questions.find((e) => e.answered === false)
  if (incomplete || form_questions.length === 0) {
    is_complete.value = false
  } else {
    is_complete.value = true
  }
})
</script>

<style scoped>
.steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.steps-wrapper > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.steps-wrapper > div > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.delete-button {
  float: right;
  margin-top: -30px;
}

.composite-dynform-trainee-card-questions {
  margin-bottom: 15px;
}

.steps-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
}
</style>
