import { defineStore } from 'pinia'
import { useGAEAPI } from '@/composable/gae_api'
import type { User, APIUsersResponse } from 'types/api/user'
import type { APIConsultantPATCHRequest, APIConsultantPATCHResponse, APIConsultantPOSTRequest, APIConsultantPOSTResponse, APIConsultantSearchResponse, Consultant, ConsultantSearch, ConsultantSearchParams, APIConsultantGETResponse } from 'types/api/consultant'
import type { AxiosError } from 'axios'
import { useToast } from 'vue-toast-notification'
import { handleErrorNotification } from '@/services/service_api'

type UserStoreState = {
  user?: User
  users: User[]
  consultant: Consultant
  consultants: Consultant[]
  searchResult: ConsultantSearch[]
  fetching: boolean
  busy: boolean
}

export const useUserStore = defineStore('user', {
  state: (): UserStoreState => ({
    users: [],
    consultants: [],
    consultant: {} as Consultant,
    searchResult: [],
    fetching: false,
    busy: false
  }),
  getters: {
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    getConsultant: (state) => state.consultant,
    getConsultants: (state) => state.consultants,
    getSearchResults: (state) => state.searchResult,
    getBusy: (state) => state.busy
  },
  actions: {
    setBusy(value: boolean) {
      this.busy = value;
    },
    async fetchAll(options: { page: number, deleted: boolean }) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.get<APIUsersResponse>(`/api/consultants`, {
        headers: {
          Accept: "application/json",
        }
      }).then(response => {
        this.consultants = response.data;
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async post(contract: APIConsultantPOSTRequest) {
      const api = useGAEAPI()
      this.fetching = true;
      return api.instance.post<APIConsultantPOSTResponse>(
        '/api/consultants',
        contract
      ).then(response => {
        const toast = useToast();
        this.addConsultant(response.data)
        toast.success(`L'intervenant a été créé.`)
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      })
    },
    async clearSearch() {
      this.search(undefined, false);
    },
    async search(params: ConsultantSearchParams, notify = true) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.get<APIConsultantSearchResponse>(
        `/api/consultants/search`, { params }
      ).then((response) => {
        this.searchResult = response.data;
        if (notify) {
          const toast = useToast();
          toast.success(`La liste des intervenants a été affinée.`)
        }
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async get(id: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.get<APIConsultantGETResponse>(
        `/api/consultants/${id}`,
      ).then((response) => {
        this.consultant = response.data;
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async patch(id: number, contract: APIConsultantPATCHRequest) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.patch<APIConsultantPATCHResponse>(
        `/api/consultants/${id}`,
        contract
      ).then((response) => {
        const toast = useToast();
        this.updateConsultant(response.data);
        toast.success(`L'intervenant ${response.data.acronym} a été modifié.`)
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async delete(id: number) {
      const api = useGAEAPI()
      this.fetching = true
      api.instance.delete(
        `/api/consultants/${id}`,
      ).then(() => {
        const toast = useToast();
        this.deleteConsultant(id);
        toast.success(`L'intervenant a été supprimé.`)
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    addConsultant(consultant: Consultant) {
      this.consultants.push(consultant);
    },
    updateConsultant(consultant: Consultant) {
      const index = this.consultants.findIndex(c => c.id === consultant.id);
      this.consultants[index] = consultant;
    },
    deleteConsultant(id: number) {
      const index = this.consultants.findIndex(c => c.id === id);
      this.consultants.splice(index, 1);
    }
  }
})
