import { defineStore } from 'pinia'
import {
  applyFilters,
  convertToDayMap,
  makeCalendarEvent,
  type InterventionDayItem
} from '@/services/service_calendar'
import { useCalendarStore } from './store_calendar'
import { useGAEAPI } from '@/composable/gae_api'
import type {
  InterventionGroup,
  APIInterventionPATCHRequest,
  APIInterventionPATCHResponse,
  APIInterventionPOSTRequest,
  APIInterventionPOSTResponse,
  APIInterventionsResponse,
  Intervention,
  InterventionFilters,
  InterventionInputOptions
} from 'types/api/intervention'
import { handleErrorNotification } from '@/services/service_api'
import { useToast } from 'vue-toast-notification'
import type { InterventionGroupPATCH } from 'types/api/intervention';
import { resyncRelationships } from '@/services/service_intervention'

type InterventionStoreState = {
  intervention?: Intervention
  interventions: Map<string, InterventionDayItem>
  deleted_interventions: Map<string, InterventionDayItem>
  renderedCount: number
  rendered: number
  lastLoaded: boolean
  filters: InterventionFilters
  fetchedMonths: string[]
  fetchedSearch: Intervention[]
  fetching: boolean
}

export const useInterventionStore = defineStore('invervention', {
  state: (): InterventionStoreState => ({
    interventions: new Map<string, InterventionDayItem>(),
    deleted_interventions: new Map<string, InterventionDayItem>(),
    renderedCount: 0,
    rendered: 1,
    lastLoaded: false,
    filters: {},
    fetchedMonths: [],
    fetchedSearch: [],
    fetching: false
  }),
  getters: {
    getIntervention: (state) => state.intervention,
    getInterventions: (state) => state.interventions,
    isFeching: (state) => state.fetching,
    getLastDay: (state) => {
      let interventions = state.interventions;
      if (state.filters.deleted) {
        interventions = new Map([...interventions, ...state.deleted_interventions]);
      }
      const last = [...interventions][interventions.size - 1]
      if (last) {
        return last[0]
      }
    },
    getRenderedDays: (state) => {
      state.renderedCount = 0
      let interventions = state.interventions;
      const output = new Map<string, InterventionDayItem>()
      const calendar_store = useCalendarStore()
      let count = 0

      if (state.filters.deleted) {
        interventions = new Map([...interventions, ...state.deleted_interventions]);
      }

      for (const [day, value] of interventions) {
        const date = new Date(day)
        if (date < calendar_store.getCurrentDay) {
          continue
        }

        const filtered_value = applyFilters(value, state.filters)
        output.set(day, filtered_value)
        state.renderedCount += filtered_value.interventions.length
        count++

        if (count >= state.rendered) break
      }

      return output
    },
    getRenderedCount: (state) => {
      return state.renderedCount
    },
    getCategories: (state) => {
      const output: string[] = []
      state.interventions.forEach((day) => {
        for (let i = 0; i < day.interventions.length; i++) {
          const intervention = day.interventions[i]
          if (!intervention.skills) continue
          for (let j = 0; j < intervention.skills.length; j++) {
            if (!output.includes(intervention.skills[j].shortname)) {
              output.push(intervention.skills[j].shortname)
            }
          }
        }
      })
      return output
    },
    getConsultants: (state) => {
      const output: SelectOption[] = []
      const unique: string[] = [];
      state.interventions.forEach((day) => {
        day.interventions.forEach((intervention) => {
          if (intervention.consultant && !unique.includes(intervention.consultant.id.toString())) {
            unique.push(intervention.consultant.id.toString())
            output.push({
              label: intervention.consultant.name,
              value: intervention.consultant.id.toString()
            });
          }
        })
      })
      return output;
    },
    getCommercials: (state) => {
      const output: SelectOption[] = []
      const unique: string[] = []
      state.interventions.forEach((day) => {
        day.interventions.forEach((intervention) => {
          if (intervention.salesperson && !unique.includes(intervention.salesperson.id.toString())) {
            unique.push(intervention.salesperson.id.toString());
            output.push({
              label: `${intervention.salesperson.firstname} ${intervention.salesperson.lastname}`,
              value: intervention.salesperson.id.toString()
            });
          }
        })
      })
      return output;
    },
    getRefereds: (state) => {
      const output: SelectOption[] = []
      const unique: number[] = []
      state.interventions.forEach((day) => {
        day.interventions.forEach((intervention) => {
          if (intervention.referent && !unique.includes(intervention.referent.id)) {
            unique.push(intervention.referent.id);
            output.push({
              label: `${intervention.referent.firstname} ${intervention.referent.lastname}`,
              value: intervention.referent.id.toString()
            });
          }
        })
      })
      return output;
    },
    getClients: (state) => {
      const output: SelectOption[] = []
      const unique: string[] = []
      state.interventions.forEach((day) => {
        day.interventions.forEach((intervention) => {
          if (intervention.customer && !unique.includes(intervention.customer)) {
            unique.push(intervention.customer);
            output.push({
              label: intervention.customer,
              value: intervention.customer
            });
          }
        })
      })
      return output;
    },
    getBackers: (state) => {
      const output: SelectOption[] = []
      const unique: string[] = []
      state.interventions.forEach((day) => {
        day.interventions.forEach((intervention) => {
          if (intervention.backer && !unique.includes(intervention.backer)) {
            unique.push(intervention.backer);
            output.push({
              label: intervention.backer,
              value: intervention.backer
            });
          }
        })
      })
      return output;
    },
    getInterventionByID: (state) => {
      return (id: number): Intervention | undefined => {
        for (const month of state.interventions) {
          const intervention = month[1].interventions.find(e => e.id === id)
          if (intervention) return intervention
        }
        return undefined;
      }
    }
  },
  actions: {
    async complete(intervention: Intervention) {
      const api = useGAEAPI();
      this.fetching = true
      return api.instance.post(`/api/interventions/${intervention.id}/complete`).then(() => {
        intervention.missionStatus = "complete";
        this.updateIntervention(intervention);

        const toast = useToast();
        toast.success("L'intervention à été complétée.", {
          duration: 5000
        })
      }).finally(() => {
        this.fetching = false;
      })
    },
    async validate(intervention: Intervention) {
      const api = useGAEAPI();
      this.fetching = true
      return api.instance.patch(`/api/interventions/${intervention.id}/validate`, null).then(() => {

        intervention.missionStatus = "documents_ok";
        this.updateIntervention(intervention);

        const toast = useToast();
        toast.success("L'intervention à été validée et terminée ! 🎉", {
          duration: 5000
        })
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      })
    },
    async refresh() {
      const toast = useToast();
      const fetched_months = this.fetchedMonths;
      this.fetchedMonths = [];
      this.interventions = new Map();

      for (const date_str of fetched_months) {
        const year_index = date_str.length - 4;
        const month = date_str.substring(0, year_index)
        const year = date_str.substring(year_index);
        const date = new Date(parseInt(year), parseInt(month));
        this.fetchMonth(date);
      }

      toast.info("Les données ont été actualisées.")
    },
    async fetchSearch(search_option: InterventionInputOptions) {
      const api = useGAEAPI()
      const response = await api.instance.get<APIInterventionsResponse>(`/api/interventions/search`, {
        params: search_option
      })

      this.fetchedSearch = response.data;
    },
    /**
     * Fetch a month from the API and merge it with the interventions map.
     * If the month is already cached in the store it wont fetch the API.
     * @param date Date of the current month
     */
    async fetchMonth(date: Date) {
      const api = useGAEAPI()
      const output: Intervention[] = []
      const d_str = `${date.getMonth()}${date.getFullYear()}`
      if (this.fetchedMonths.includes(d_str)) {
        return
      }

      this.fetching = true
      const response = await api.instance.get<APIInterventionsResponse>(`/api/interventions`, {
        params: {
          m: date.getMonth() + 1,
          y: date.getFullYear()
        }
      })

      for (let i = 0; i < response.data.length; i++) {
        const intervention = response.data[i]
        const i_date = new Date(intervention.date)
        const i_str = `${i_date.getMonth()}${i_date.getFullYear()}`

        if (i_str === d_str) {
          output.push(intervention)
        }
      }

      // Ordering the result by date in a new hashmap
      const data = [...this.interventions, ...convertToDayMap(output)]
      this.interventions = new Map(data.sort(([a], [b]) => a.localeCompare(b)))
      this.fetchedMonths.push(d_str)
      this.fetching = false
    },
    async post(contract: APIInterventionPOSTRequest) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.post<APIInterventionPOSTResponse>(
        '/api/interventions',
        contract
      ).then(response => {
        const toast = useToast();
        this.addIntervention(response.data)
        toast.success(`L'intervention a été créée.`)
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      })
    },
    async patch(id: number, contract: APIInterventionPATCHRequest) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.patch<APIInterventionPATCHResponse>(
        `/api/interventions/${id}`,
        contract
      ).then((response) => {
        const toast = useToast();
        this.updateIntervention(response.data);
        toast.success(`L'intervention a été modifiée.`)
      }).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async delete(intervention: Intervention) {
      const api = useGAEAPI()
      api.instance.delete(`/api/interventions/${intervention.id}`).then(() => {
        const toast = useToast();
        this.removeIntervention(intervention)
        toast.success(`L'intervention a été supprimée.`, { duration: 10000 })
      }).catch(handleErrorNotification)
    },
    async export(intervention: Intervention) {
      const api = useGAEAPI()
      api.instance.get(`/api/interventions/${intervention.id}/pdf`, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch(handleErrorNotification);
    },
    addIntervention(intervention: Intervention) {
      const month = this.interventions.get(intervention.date)
      if (!month) {
        this.interventions.set(intervention.date, {
          interventions: [intervention],
          event: makeCalendarEvent(intervention)
        })
        this.sort();
      } else {
        month.interventions.push(intervention)
        month.event.extendedProps.count++
      }
      const calendar_store = useCalendarStore();
      const intervention_date = new Date(intervention.date);
      calendar_store.setCurrentDay(intervention_date);
    },
    updateIntervention(intervention: Intervention) {

      this.interventions.forEach(month => {
        const index = month.interventions.findIndex((i) => i.id === intervention.id)
        if (index === -1) return

        const old_intervention = month.interventions[index]
        if (old_intervention.date !== intervention.date) {
          const new_month = this.interventions.get(intervention.date)
          if (!new_month) {
            this.interventions.set(intervention.date, {
              interventions: [intervention],
              event: makeCalendarEvent(intervention)
            })
          } else {
            new_month.interventions.push(intervention)
            new_month.event.extendedProps.count++
          }

          month.interventions.splice(index, 1)
          month.event.extendedProps.count--
          this.sort();
        } else {
          month.interventions[index] = intervention
        }
      })
      resyncRelationships(intervention)
    },
    removeIntervention(intervention: Intervention) {
      const month = this.interventions.get(intervention.date)
      if (!month) return

      const index = month.interventions.findIndex((i) => i.id === intervention.id)
      if (index === -1) return

      month.interventions.splice(index, 1)
      month.event.extendedProps.count--
    },
    renderReset(n: number = 3) {
      this.rendered = n
    },
    renderNext(n: number = 1) {
      this.rendered += n
    },
    renderNextMonth() {
      if (!this.getLastDay) {
        return
      }

      const last_event_date = new Date(this.getLastDay)
      const dayOfMonth = last_event_date.getDate();

      last_event_date.setMonth(last_event_date.getMonth() + 1);

      // if next month has less days than previous month set date to last day of last_event_date previous month because in this case getMonth() +1 skip a month
      if (last_event_date.getDate() !== dayOfMonth) {
        last_event_date.setDate(0);
      }

      this.fetchMonth(last_event_date)
      this.rendered += 3
    },
    resetFilters() {
      this.filters = {}
    },
    sort() {
      const array = Array.from(this.interventions);
      this.interventions = new Map(array.sort(([a], [b]) => a.localeCompare(b)))
    },
    async addInterventionGroup(intervention_id: number, start: string, end: string, intern_count: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.post<InterventionGroup>(
        `/api/intervention_groups`,
        { intervention_id, start, end, intern_count }
      ).catch(handleErrorNotification).finally(() => {
        this.fetching = false
      });
    },
    async deleteGroup(group_id: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance
        .delete(`/api/intervention_groups/${group_id}`)
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false
        })
    },
    async editGroup(group_id: number, start: string, end: string, intern_count: string) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance
        .patch<InterventionGroup>(`/api/intervention_groups/${group_id}`, {
          start: start,
          end: end,
          intern_count: parseInt(intern_count)
        })
        .then((response) => {
          const toast = useToast()
          toast.success("Groupe modifié.");
          return response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false
        })
    },
  }
})
