<template>
  <div
    v-if="items.length > 0"
    class="input-check-list"
    :class="{ open, bot: y > height / 2 }"
    ref="button"
  >
    <h4 class="mb-20">{{ label }}</h4>
    <div
      v-for="option of items"
      :key="option.value"
      class="option"
      @click="!option.disabled && select(option.value)"
    >
      <Check
        v-if="selected.find((e) => e == option.value)"
        class="icon"
        color="var(--vc-white)"
        :size="16"
      />
      <input
        type="checkbox"
        v-model="selected"
        :value="option.value"
        :class="{ disabled: option.disabled }"
      />
      <p>{{ option.label }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useElementBounding, useWindowSize } from '@vueuse/core'
import { Check } from 'lucide-vue-next'
import { computed, ref } from 'vue'

const props = defineProps<{
  items: { label: string; value: string | number; disabled?: boolean }[]
  label: string
  modelValue?: (string | number)[]
}>()
const emits = defineEmits(['update:modelValue'])

const open = ref(false)
const menu = ref<HTMLElement>()
const button = ref<HTMLElement>()
onClickOutside(menu, () => {
  open.value = false
})
const { height } = useWindowSize()
const { y } = useElementBounding(button)
const selected = computed({
  get: () => {
    return props.modelValue ?? []
  },
  set: (v?: (string | number)[]): void => {
    emits('update:modelValue', v)
  }
})

function select(value: string | number) {
  const index = selected.value.findIndex((v) => v === value)
  if (index == -1) {
    selected.value.push(value)
  } else {
    selected.value.splice(index, 1)
  }
  emits('update:modelValue', selected.value)
}
</script>

<style scoped lang="scss">
input[type='checkbox'] {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-dark);
}

.icon {
  position: absolute;
  z-index: 20;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.option {
  position: relative;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  width: fit-content;
  margin-bottom: 10px;
}

.disabled {
  background-color: rgba(var(--vcrgb-dark), 0.2) !important;
  color: rgba(var(--vcrgb-dark), 0.4) !important;
  pointer-events: none !important;
  border-color: rgba(var(--vcrgb-dark), 0.1) !important;
}

.option > p {
  cursor: pointer;
  user-select: none;
}
</style>
