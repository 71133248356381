<template>
  <div class="input-common" :class="{ error: errors && errors.length > 0 }">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <input
      size="1"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :required="required"
      @input="emits('update:modelValue', extractEventValue($event))"
    />
    <div v-if="errors && errors.length > 0" class="input-common__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { AlertCircle } from 'lucide-vue-next'

const props = withDefaults(
  defineProps<{
    id: string
    label: string
    type?: string
    modelValue?: string | number
    placeholder?: string
    required?: boolean
    uppercase?: boolean
    capitalize?: boolean
    errors?: ErrorObject[]
  }>(),
  {
    type: 'text'
  }
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

function extractEventValue(event: Event) {
  const value = (event.target as HTMLInputElement).value
  if (props.uppercase === true) {
    return value.toUpperCase()
  }

  if (props.capitalize === true) {
    return String(value).charAt(0).toUpperCase() + String(value).slice(1)
    value
  }

  return value
}
</script>

<style scoped lang="scss">
.input-common {
  display: flex;
  flex-direction: column;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    align-items: center;
    gap: 5px;
  }
}

.input-common > label {
  margin-bottom: 15px;
}

.input-common > input {
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  padding: 20px 20px;
  box-sizing: content-box;
}

.error > input {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}
</style>
