<template>
  <div class="common-qrcode">
    <div>
      <a :href="link" target="_blank">
        <div v-if="props.link">
          <img :src="qrcode" alt="QR Code" ref="qrCodeImage" />
        </div>
      </a>
    </div>
    <div class="qrcode-actions">
      <CommonButton @click="copyURL()" class="copy-button" icon="Copy" size="small" color="primary"
        >Lien</CommonButton
      >
      <CommonButton
        @click="downloadQrCode()"
        class="copy-button"
        icon="Download"
        size="small"
        color="primary"
        >Telecharger</CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQRCode } from '@vueuse/integrations/useQRCode'
import { ref } from 'vue'
import { useToast } from 'vue-toast-notification'
import CommonButton from './CommonButton.vue'

const toast = useToast()
const props = withDefaults(
  defineProps<{
    link: string
  }>(),
  {}
)
const text = ref(props.link)
const qrcode = useQRCode(text)

const qrCodeImage = ref<HTMLImageElement>()

async function copyURL() {
  try {
    await navigator.clipboard.writeText(props.link)
    toast.success('Lien copié')
  } catch ($e) {
    toast.error('Cannot copy')
  }
}

function downloadQrCode() {
  if (qrCodeImage.value) {
    const link = document.createElement('a')
    link.href = qrCodeImage.value.src
    link.download = 'qrcode.png'
    link.click()
  }
}
</script>

<style scoped lang="scss">
.common-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-actions {
  display: flex;
  gap: 10px;
}
</style>
